import { motion } from "framer-motion"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"

import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"

import { Campo } from "../../../componentes/form/campo"
import { Spinner } from "../../../componentes/Spinner"

import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { Accordion, Row } from "react-bootstrap"
import { MdCheckCircle, MdCheckCircleOutline } from "react-icons/md"
import { Alert, Backdrop, Card } from "@mui/material"
import { configAnim } from "../../../servicos/config/configAnim"

import RelatorioAcertos from "./RelatorioAcertos"
import RelatorioConcluidos from "./RelatorioConcluidos"
import ListagemRelatorio from "./componentes/listagem"

import AppAPI from "./../../../servicos/AppAPI"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import MensagemApp from "../../../servicos/config/MensagemApp"
import Refresher from "../../../componentes/uteis/Refresher"
import { useLocation } from "react-router-dom"

export default function Relatorios() {
    const [carregando, setCarregando] = useState(true)

    const { state } = useLocation()

    const [menu, setMenu] = useState(false)
    const [tabActive, setTabActive] = useState('A')
    const [listaCotacoes, setListaCotacoes] = useState('')

    const [configBuscas, setConfigBuscas] = useState({})

    const [periodo, setPeriodo] = useState('hoje')

    const [backdrop, setBackdrop] = useState(false)
    const [backdropConteudo, setBackdropConteudo] = useState('')

    const { dados, setDados } = useContext(ContextoGeral)
    const [erroAPI, setErroAPI] = useState(false)
    const [scroll, setScroll] = useState(true)

    const mudarTab = (value) => {
        setTabActive(value)
    }

    const handleBackdrop = (relatorio) => {
        setBackdrop(true)
        let acertos = {}
        acertos.dados = relatorio
        setBackdropConteudo(<ListagemRelatorio acertos={acertos} />)
    }

    async function buscarConfig() {
        let jsonConfig = await AppAPI.buscarConfigRelatorio(dados)
        console.log(jsonConfig)
        setErroAPI(false)

        if (jsonConfig.resultado) {
            setConfigBuscas(jsonConfig.dados)
        } else {
            setErroAPI(true)
            if (typeof window.AndroidInterface !== 'undefined') {
                window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)

            } else {
                alert(MensagemApp.msgErroApi)
            }
        }
        setCarregando(false)
    }

    async function buscarCotacoes() {
        setListaCotacoes(<Spinner />)
        setErroAPI(false)

        let retorno = await AppAPI.buscarRelatorioCotacoes(dados, periodo)

        if (retorno?.codigo) {
            if (retorno?.resultado) {
                if (retorno.dados != '') {
                    setListaCotacoes(
                        <Card className="bgGradientGrey p-3">
                            <div>{retorno.dados.aceitos.label}: {retorno.dados.aceitos.quantidade}</div>
                            <div>{retorno.dados.recusados.label}: {retorno.dados.recusados.quantidade}</div>
                            <div>{retorno.dados.concluidos.label}: {retorno.dados.concluidos.quantidade}</div>
                        </Card>
                    )
                } else {
                    setListaCotacoes(
                        <Alert>
                            {retorno.mensagem}
                        </Alert>
                    )
                }
            } else {
                setListaCotacoes(
                    <Alert>
                        Nenhuma cotação encontrada
                    </Alert>
                )
            }
        } else {

            setErroAPI(true)
            if (typeof window.AndroidInterface !== 'undefined') {
                window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)

            } else {
                alert(MensagemApp.msgErroApi)
            }
        }
    }

    const handleBackdropClose = (event) => {
        setBackdropConteudo('')
        setBackdrop(false)
    }

    const handleCotacaoPeriodo = async (e) => {
        setPeriodo(e.target.value)
    }

    useEffect(() => {
        if (carregando) {
            if (state?.tab && scroll) {
                mudarTab(state.tab)
            }

            buscarConfig()
        }
    }, [carregando])

    useLayoutEffect(() => {
        if (!carregando && state?.tab && state.tab == "C" && scroll) {
            let el = document.querySelector(".nav.nav-pills")
            el.scrollLeft = 500

            setScroll(false)
        }
    })

    return (
        <>
            <motion.div
                style={{ height: '100%' }}
                initial={configAnim.initial}
                animate={configAnim.animate}
                exit={configAnim.exit}
                transition={configAnim.transition}
            >
                <TopoMenu voltar="/app/home" titulo="RELATÓRIOS" setShow={setMenu} />
                <div className="mx-2 mt-3">
                    {carregando ? (
                        <Spinner />
                    ) : (
                        <>
                            {
                                erroAPI ? (<>
                                    <div className="mx-2">
                                        <Refresher
                                            setCarregando={setCarregando}
                                        />
                                        <Alert severity="error" className="text-center mt-4">
                                            {MensagemApp.msgErroApi}
                                        </Alert>
                                    </div>
                                </>) : (

                                    <Tabs
                                        variant="pills"
                                        activeKey={tabActive}
                                        id="tabs"
                                    >
                                        {configBuscas.campos.cotacoes.exibicao && (<Tab
                                            title={
                                                <div
                                                    className="d-flex h5 mb-0 py-2 align-items-center"
                                                    onClick={() => { mudarTab('A') }}
                                                >
                                                    {tabActive === 'A' ? (
                                                        <MdCheckCircle />
                                                    ) : (
                                                        <MdCheckCircleOutline />
                                                    )}
                                                    {configBuscas.campos.cotacoes.label}
                                                </ div>
                                            }
                                            eventKey={'A'}
                                        >
                                            <div className="my-3">
                                                <Campo
                                                    tipo="select"
                                                    onChange={handleCotacaoPeriodo}
                                                    value={periodo}
                                                    options={configBuscas.filtros.cotacoes.periodo.opcoes}
                                                />
                                                <Row className="g-0 mt-3">
                                                    <button onClick={buscarCotacoes}>Buscar</button>
                                                </Row>
                                            </div>
                                            {listaCotacoes}
                                        </Tab>)}
                                        {configBuscas.campos.servicosConcluidos.exibicao ? (
                                            <Tab
                                                title={
                                                    <div
                                                        id="tabAcertos"
                                                        className="d-flex h5 mb-0 py-2 align-items-center"
                                                        onClick={() => { mudarTab('B') }}
                                                    >
                                                        {tabActive === 'B' ? (
                                                            <MdCheckCircle />
                                                        ) : (
                                                            <MdCheckCircleOutline />
                                                        )}
                                                        {configBuscas.campos.servicosConcluidos.label}
                                                    </div>
                                                }
                                                eventKey={'B'}
                                            >
                                                <RelatorioConcluidos config={configBuscas} />
                                            </Tab>
                                        ) : ("")}
                                        {configBuscas.campos.acertos.exibicao ? (
                                            <Tab
                                                title={
                                                    <div
                                                        className="d-flex h5 mb-0 py-2 align-items-center"
                                                        onClick={() => { mudarTab('C') }}
                                                    >
                                                        {tabActive === 'C' ? (
                                                            <MdCheckCircle />
                                                        ) : (
                                                            <MdCheckCircleOutline />
                                                        )}
                                                        {configBuscas.campos.acertos.label}
                                                    </div>
                                                }
                                                eventKey={'C'}
                                            >
                                                <RelatorioAcertos config={configBuscas} handleBackdrop={handleBackdrop} />
                                            </Tab >
                                        ) : ("")}
                                    </Tabs >
                                )
                            }
                        </>

                    )}
                    <MenuLateral show={menu} setShow={setMenu} ativo="3" />
                </div >
            </motion.div >

            <Backdrop
                open={backdrop}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "#fff",
                    height: '100%',
                    overflowY: 'auto',
                    display: 'block'
                }}
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div>
                        <div
                            className="mx-3 mt-2 pt-2 d-flex justify-content-end position-sticky top-0"
                            style={{ zIndex: '800' }}
                        >
                            <button
                                className='btn-close'
                                onClick={handleBackdropClose}
                            />
                        </div>
                        <div className="p-3">
                            {backdropConteudo}
                        </div>
                    </div>
                </motion.div>
            </Backdrop>
        </>
    )
}