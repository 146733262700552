import { motion } from "framer-motion"
import { useContext, useState } from "react"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"
import { configAnim } from "../../../servicos/config/configAnim"

import { ContextoGeral } from "../../../contextos/ContextoGeral"

export default function PoliticaPrivacidade() {
    const [menu, setMenu] = useState(false)
    const { dados } = useContext(ContextoGeral)

    return (
        <motion.div
            className="telaAjustada"
            initial={configAnim.initial}
            animate={configAnim.animate}
            exit={configAnim.exit}
            transition={configAnim.transition}
        >
            <TopoMenu voltar="/app/configuracoes" titulo="POLÍTICA DE PRIVACIDADE" setShow={setMenu} />
            <div>
                <iframe
                    style={{
                        height: 'calc(100vh - 3rem)',
                        width: '100%'
                    }}
                    src={"https://" + dados.dominio + "/politicaPrivacidadeAndroid"} title="política de privacidade"
                />
            </div>
            <MenuLateral show={menu} setShow={setMenu} ativo="4" />
        </motion.div>
    )
}