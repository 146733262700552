import { motion } from "framer-motion"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import RadarVerificacao from "../../../componentes/usuario/RadarVerificacao"

import { Spinner } from "../../../componentes/Spinner"

import { Card } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MdCheck, MdClose } from "react-icons/md"

import styled from "styled-components"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import ProfissionalAPI from "./../../../servicos/ProfissionalAPI"

import CheckPermission from "../../../servicos/config/CheckPermission"
import { configAnim } from "../../../servicos/config/configAnim"

const Div = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
`

export default function TestarStatus() {
	const handleMenu = () => { setMenu(!menu) }

	const { dados } = useContext(ContextoGeral)

	const [menu, setMenu] = useState(false)
	const [verificando, setVerificando] = useState(true)
	const [suspensao, setSuspensao] = useState(false)

	const reverificar = () => {
		setConex(prev => ({ ...prev, loading: true }))
		setLocal(prev => ({ ...prev, loading: true }))
		setOptmi(prev => ({ ...prev, loading: true }))
		setOnline(prev => ({ ...prev, loading: true }))
		setSuspend(prev => ({ ...prev, loading: true }))
		setVerificando(true)
	}

	const [conex, setConex] = useState({
		texto: "Conexão de rede",
		loading: true,
		valid: false
	})

	const [local, setLocal] = useState({
		texto: "Localização",
		loading: true,
		valid: false
	})

	const [optmi, setOptmi] = useState({
		texto: "Bateria desotimizada",
		loading: true,
		valid: false
	})

	const [online, setOnline] = useState({
		texto: "Prof. online",
		loading: true,
		valid: false
	})

	const [suspend, setSuspend] = useState({
		texto: "Prof. habilitado (Suspensão)",
		loading: true,
		valid: false
	})

	const [sucesso, setSucesso] = useState(false)
	const [estadoRadar, setEstadoRadar] = useState(true)

	useEffect(() => {
		console.log(conex)
	}, [conex])

	useEffect(() => {
		if (verificando) {
			setEstadoRadar(true)

			setTimeout(() => {
				setConex(prev => ({
					...prev,
					loading: false,
					valid: window.AndroidInterface ? window.AndroidInterface?.checkConnectionAvailable() : false
				}))
			}, 500)
			setTimeout(() => {
				setLocal(prev => ({
					...prev,
					loading: false,
					valid: window.AndroidInterface ? window.AndroidInterface?.checkLocationAvailable() : false
				}))
			}, 1000)
			setTimeout(() => {
				setOptmi(prev => ({
					...prev,
					loading: false,
					valid: window.AndroidInterface ? CheckPermission.checkBatteryOptimization() : false
				}))
			}, 1500)
			setTimeout(() => {
				setOnline(prev => ({
					...prev,
					loading: false,
					valid: dados?.statusOnline && dados?.statusOnline
				}))
			}, 2000)
			setTimeout(() => {
				setSuspend(prev => ({
					...prev,
					loading: false,
					valid: buscarAtivo()
				}))
				setEstadoRadar(true)
			}, 2500)

		} else {
			setEstadoRadar(false)
		}
	}, [verificando])

	async function buscarAtivo() {
		let retornoAtivo = await ProfissionalAPI.buscarAtivo(dados)

		console.log(retornoAtivo)

		if (retornoAtivo?.codigo == 400 || retornoAtivo?.codigo == 401) {
			return false
		} else if (retornoAtivo?.resultado) {
			let ativo = retornoAtivo.dados.status

			if (ativo == 'P' || ativo == 'U')
				return false
			else return true
		} else return false
	}

	async function checarModulo() {
		const retorno = await ProfissionalAPI.checarModulo(dados, "MPSDPROF")
		if (retorno.resultado)
			setSuspensao(true)
	}

	useEffect(() => {
		setEstadoRadar(true)
		setVerificando(true)

		checarModulo()
	}, [])

	useEffect(() => {
		if (conex.valid && local.valid && optmi.valid && online.valid && suspend.valid)
			setSucesso(true)
		if (!conex.loading && !local.loading && !optmi.loading && !online.loading && !suspend.loading)
			setVerificando(false)
	}, [conex, local, optmi, online, suspend])

	return <motion.div
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
	>
		<Div>
			<TopoMenu voltar="/app/home" titulo="STATUS" setShow={handleMenu} />
			<RadarVerificacao estado={estadoRadar} sucesso={sucesso} fn={reverificar} />
			<Card className="mt-5 mx-3 p-3">
				<div className="row d-flex align-items-center" style={{ height: "40px" }}>
					<div className="col-8 small">
						{conex.texto}
					</div>
					<div className="col-4 text-end">
						{conex.loading
							? <Spinner key={conex.id} right />
							: conex.valid
								? <MdCheck key={conex.id} className="checkSuccess" size={25} />
								: <MdClose key={conex.id} className="crossDanger" size={25} />}
					</div>
				</div>
				<div className="row d-flex align-items-center" style={{ height: "40px" }}>
					<div className="col-8 small">
						{local.texto}
					</div>
					<div className="col-4 text-end">
						{local.loading
							? <Spinner key={local.id} right />
							: local.valid
								? <MdCheck key={local.id} className="checkSuccess" size={25} />
								: <MdClose key={local.id} className="crossDanger" size={25} />}
					</div>
				</div>
				<div className="row d-flex align-items-center" style={{ height: "40px" }}>
					<div className="col-8 small">
						{optmi.texto}
					</div>
					<div className="col-4 text-end">
						{optmi.loading
							? <Spinner key={optmi.id} right />
							: optmi.valid
								? <MdCheck key={optmi.id} className="checkSuccess" size={25} />
								: <MdClose key={optmi.id} className="crossDanger" size={25} />}
					</div>
				</div>
				<div className="row d-flex align-items-center" style={{ height: "40px" }}>
					<div className="col-8 small">
						{online.texto}
					</div>
					<div className="col-4 text-end">
						{online.loading
							? <Spinner key={online.id} right />
							: online.valid
								? <MdCheck key={online.id} className="checkSuccess" size={25} />
								: <MdClose key={online.id} className="crossDanger" size={25} />}
					</div>
				</div>
				{suspensao && <div className="row d-flex align-items-center" style={{ height: "40px" }}>
					<div className="col-8 small">
						{suspend.texto}
					</div>
					<div className="col-4 text-end">
						{suspend.loading
							? <Spinner key={suspend.id} right />
							: suspend.valid
								? <MdCheck key={suspend.id} className="checkSuccess" size={25} />
								: <MdClose key={suspend.id} className="crossDanger" size={25} />}
					</div>
				</div>}
			</Card >
			<MenuLateral show={menu} setShow={handleMenu} ativo="1" />
		</Div>
	</motion.div>
}