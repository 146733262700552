import { Alert, Button, Card, IconButton, Popover, Zoom } from "@mui/material"
import { motion } from "framer-motion"
import { useLayoutEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { MdAttachMoney, MdCancel, MdCheck, MdCreditCard, MdMonetizationOn, MdTouchApp, MdUndo } from "react-icons/md"
import { Spinner } from "../../../componentes/Spinner"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import Status from "../../../componentes/uteis/Status"

import { Backdrop } from "@mui/material"
import { useContext, useEffect } from "react"
import { Marcador } from "../../../componentes/servicos/cardServico"
import Refresher from "../../../componentes/uteis/Refresher"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import ServicoAPI from "../../../servicos/ServicoAPI"
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import { configAnim } from "../../../servicos/config/configAnim"

import { AttachMoney, Close, CreditCard, Pix, PointOfSale, Refresh, RssFeed, Subtitles } from "@mui/icons-material"

import { format, parse } from "date-fns"
import { useRef } from "react"
import CardEndereco from "../../../componentes/form/CardEndereco"
import useTestChecker from "../../../hooks/general/useTestChecker"
import { print, verificarBootClick } from '../../../servicos/Uteis.js'
import AlertDialog, { BootClickAlert, FakeGpsAlert } from "./../../../componentes/gerais/Dialog.jsx"
import { Balloon } from "./styles"

import FakeGPSBlock from "../../../componentes/uteis/FakeGPSBlock"
import CheckPermission from "../../../servicos/config/CheckPermission"
import icon from "./../../../assets/icones/relogio.svg"
import { useLocation } from "react-router-dom"

function formaPg(forma) {
	let icon = <></>

	const sx = { fontSize: "1rem" }

	if (forma.sigla != "-") {

		switch (forma.sigla) {
			case "C":
				icon = <CreditCard sx={sx} />
				break
			case "T":
				icon = <AttachMoney sx={sx} />
				break
			case "L":
				icon = <AttachMoney sx={sx} />
				break
			case "P":
				icon = <PointOfSale sx={sx} />
				break
			case "D":
				icon = <AttachMoney sx={sx} />
				break
			case "S":
				icon = <AttachMoney sx={sx} />
				break
			case "":
			case "F":
				icon = <AttachMoney sx={sx} />
				break
			case "M":
				icon = <PointOfSale sx={sx} />
				break
			case "H":
				icon = <Pix sx={sx} />
		}

		return <div className="d-flex align-items-center">
			{icon}
			<p className="mb-0" style={{ marginTop: "0.125rem" }}>{forma.texto}</p>
		</div>
	}
}

export default function Cotacoes() {
	const [carregando, setCarregando] = useState(true)
	const [BDLoading, setBDLoading] = useState(false)

	const [exibirFakeGPS, setExibirFakeGPS] = useState(false)
	const [exibirBootClick, setExibirBootClick] = useState(false)

	const { state } = useLocation()

	const [open, setOpen] = useState(false)
	const [anchor, setAnchor] = useState(null)
	const anchorRef = useRef()

	const [menu, setMenu] = useState(false)
	const [BD, setBD] = useState(false)
	const [modal, setModal] = useState(false)
	const [timeoutMode, setTimeoutMode] = useState(false)
	const [cotacoes, setCotacoes] = useState({})
	const [cotacaoSel, setCotacaoSel] = useState({})

	const [idAtivo, setIdAtivo] = useState(0)
	const [time, setTime] = useState(0)
	const [modo, setModo] = useState(false)
	const [titulo, setTitulo] = useState("")
	const [subTitulo, setSubTitulo] = useState("")

	const [msg, setMsg] = useState(<Spinner />)
	const [valido, setValido] = useState(false)

	const [texto, setTexto] = useState('')
	const [motivo, setMotivo] = useState('')
	const [configRecusar, setConfigRecusar] = useState('')

	const { dados, setDados } = useContext(ContextoGeral)

	const isTest = useTestChecker(dados.dominio)

	const handleMotivo = (e) => setMotivo(e.target.value)

	async function verificarAntifraude() {
		try {
			if (window.AndroidInterface) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface?.isFakeGPS()) {
					setExibirFakeGPS(true)
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)
				}
			}
		} catch {
			console.error("Não possui esta funcionalidade")
		}
	}

	async function buscarConfigs() {
		let retorno = await ServicoAPI.buscarConfigTelaCotacao(dados)

		if (retorno.resultado) {
			setTitulo(retorno.dados.titulo != "" ? retorno.dados.titulo : "COTAÇÕES")
			setSubTitulo(retorno.dados.subTitulo)
		} else setTitulo("COTAÇÕES")
	}

	async function buscarCotacoes() {
		let local = { latitude: 0, longitude: 0 }
		if (window.AndroidInterface)
			local = JSON.parse(window.AndroidInterface.getLocation())

		if ((local == '') || (local.latitude == 0 || local.longitude == 0)) {
			setMsg(MensagemApp.msgErroLaLo)
			setValido(false)
		} else {
			await ServicoAPI.buscarCotacoes(dados, local).then(res => {
				if (res?.resultado) {
					setValido(true)

					if (res.dados && typeof res.dados != "string" && typeof res.dados != "undefined") {
						if (res.dados.length == 1 && res.dados[0]?.TEMPOACEITAR) {
							setTime(res.dados[0]?.TEMPOACEITAR)
							setTimeoutMode(true)
						}

						setMsg(res.mensagem)

						if (res.dados.length == 0 && state && state?.viaNotificacao)
							setMsg("Serviço(os) enviado para outro(os) profissional(ais).")
						setCotacoes(res.dados)
					} else {
						setCotacoes({})
						if (state && state?.viaNotificacao)
							setMsg("Serviço(os) enviado para outro(os) profissional(ais).")
						else setMsg(res.mensagem)
					}
				} else {
					setValido(true)
					setMsg(MensagemApp.timeout)
				}
			}).catch(rej => {
				setValido(true)
				setMsg(MensagemApp.timeout)
			})
		}
		setCarregando(false)
	}

	async function confirmar(id, modoSelecionado, motivoRecusar = {}) {
		setBDLoading(false)
		setIdAtivo(id)

		try {
			if (window.AndroidInterface?.recoverVersion() > 100.80) {
				let bloqueio = false

				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					bloqueio = dados?.configFakeGPS?.opcao == "B"
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					if (!bloqueio)
						bloqueio = dados?.configBootClick?.opcao == "B"
				}

				if (bloqueio) return
			}

			if (modoSelecionado)
				setTexto(`Você está aceitando o serviço ${id}`)
			else {
				setTexto(`Você está recusando o serviço ${id}`)
				setConfigRecusar(motivoRecusar)
			}

			setModo(modoSelecionado)
			setBD(true)
		} catch (err) {
			console.error(err)
		}
	}

	async function responderCotacao(id, status) {
		try {
			if (window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
				if (window.AndroidInterface?.isFakeGPS) {

					if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
						setExibirFakeGPS(true)

						if (dados?.configFakeGPS?.opcao == "B")
							return
					}

					if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
						setExibirBootClick(true)

						if (dados?.configBootClick?.opcao == "B")
							return
					}
				}
			}
		} catch {
			console.error("")
		}

		if (status == 'S' && !dados?.statusOnline) {
			if (typeof window.AndroidInterface !== 'undefined')
				window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroCotacao)
			else
				alert(MensagemApp.msgErroCotacao)

			setBD(false)
		} else {
			setBDLoading(true)
			let data = {
				idProf: dados.idProf,
				idServico: id,
				status: status
			}

			if (modo != 'S') {
				data.motivo = motivo != "" ? motivo : "Expirado"
			}

			let local = {
				latitude: 0,
				longitude: 0
			}

			if (window.AndroidInterface) {
				local = JSON.parse(window.AndroidInterface.getLocation())
			}

			if (modo == "N" && configRecusar.obrigatorio && motivo.length == 0) {
				if (window.AndroidInterface) window.AndroidInterface.showAndroidVersion("Favor, preencha todos os campos obrigatórios")
			} else {

				let retorno = await ServicoAPI.responderCotacao(dados, data, local)

				if (retorno?.resultado) {
					window.AndroidInterface?.showAndroidVersion(retorno.mensagem)
				} else {
					if (retorno?.mensagem)
						window.AndroidInterface?.showAndroidVersion(retorno.mensagem)
					else
						window.AndroidInterface?.showAndroidVersion(MensagemApp.msgErroApi)
				}

				setBD(false)
				setCarregando(true)
			}
		}

		setMotivo('')
		setBD(false)
	}

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				setOpen(false)

				let json = dados
				json.tutoriais.cotacoes = false
				setDados(json)
			}, 2000)
		}
	}, [open])

	useEffect(() => {
		if (isTest && dados.tutoriais.cotacoes) {
			setTimeout(() => setOpen(true), 1500)
		}

		if (!!window.AndroidInterface) {

			if (window.AndroidInterface.recoverVersion() >= 102.00) {
				window.AndroidInterface.removeNotification(998)
			} else {
				window.AndroidInterface.removeNotification(673)
			}

		}

		if (carregando) buscarCotacoes()
	}, [carregando])

	useEffect(() => {
		if (cotacoes.length > 0) {
			setCarregando(false)
		}
	}, [cotacoes])

	useLayoutEffect(() => {
		if (anchorRef) {
			setAnchor(anchorRef)
		}
	}, [anchorRef])

	useEffect(() => {
		verificarAntifraude()
		buscarConfigs()
	}, [])

	useEffect(() => {
		try {
			if (window.AndroidInterface) {
				if (CheckPermission.checkLocationEnabled() && CheckPermission.checkBackgroundLocationAvailable())
					setValido(true)
				else throw "Localização desativada, ative para ver os seus serviços abertos"
			} else throw "Não está no celular"
		} catch (e) {
			setMsg(e)
			setValido(false)
		}
	})

	return <>
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<TopoMenu voltar="/app/home" titulo={titulo.toUpperCase()} setShow={setMenu} />
			<FakeGpsAlert
				open={exibirFakeGPS}
				setOpen={setExibirFakeGPS}
				opcao={dados?.configFakeGPS?.opcao} />

			<BootClickAlert
				open={exibirBootClick}
				setOpen={setExibirBootClick}
				opcao={dados?.configBootClick?.opcao} />

			{carregando
				? <div className="vh-100">
					<Spinner />
				</div>
				: <div className="pb-3">
					{subTitulo.length > 0 && <Card className="m-3 text-center p-2">{subTitulo}</Card>}
					{Object.values(cotacoes).length > 0
						? timeoutMode
							? <CotacaoAceitar cotacao={cotacoes[0]} time={time} confirmar={responderCotacao} reload={() => {
								window.history.replaceState({}, "")
								setCarregando(true)
							}} />
							: <>
								{Object.values(cotacoes).map((cotacao, i) => {
									const urgente = cotacao["s.urgente"] == "S"
									const economico = cotacao["s.freteExpresso"] == "S"

									return <Zoom
										key={"cotacao-" + i}
										in={Object.values(cotacoes).length > 0}
										style={{
											transitionDelay: (Object.values(cotacoes).length > 0) ? i * 200 : 0
										}}
									>
										<Card className={`m-3 p-2 bgGradientGrey ${urgente && 'text-danger'} ${economico && "text-info"}`}>
											{urgente && <div className="bg-danger mb-2 pt-3 text-light" style={{
												margin: "-1rem",
												padding: "0.5rem 1rem"
											}}>
												<div className="d-flex align-items-center gap-2">
													<img src={icon} style={{
														height: "1.25rem",
														filter: "brightness(10)"
													}} alt="" />
													Serviço urgente
												</div>
											</div>}

											{economico && <div className="bg-info mb-2 pt-3 text-light" style={{
												margin: "-1rem",
												padding: "0.5rem 1rem"
											}}>
												<div className="d-flex align-items-center gap-2">
													<MdMonetizationOn size={24} />
													Serviço econômico
												</div>
											</div>}

											<div className="d-flex justify-content-between align-items-center">
												<Col sm={3}>
													<span style={{ fontWeight: 800, fontSize: "1.25rem", lineHeight: 1, color: urgente ? 'var(--bs-danger)' : economico ? "var(--bs-info)" : 'var(--primaria)' }}>
														#{cotacao['s.id']}
													</span>
												</Col>
												<Col sm={9} className="d-flex align-items-end flex-column" style={{
													lineHeight: 1,
													opacity: 0.75,
													fontWeight: 600,
													fontSize: "0.8rem",
													color: urgente ? 'var(--bs-danger)' : economico ? "var(--bs-info)" : 'var(--primaria-darkest)'
												}}>
													{cotacao["s.dataHora"] && <DataHora data={cotacao["s.dataHora"]} />}
													<div>
														{cotacao.destaqueDistancia == "DV"
															? cotacao.labelDistancia
																? cotacao['s.distancia']
																	? <>{cotacao['labelDistancia']}: {cotacao['s.distancia']?.replace('.', ',')}km</>
																	: "distância não informada"
																: cotacao['s.distancia']
																	? <>percurso: {cotacao['s.distancia']?.replace('.', ',')}km</>
																	: "distância não informada"
															: cotacao['sm.distanciaColeta']
																? <>Distância de você: {cotacao['sm.distanciaColeta']?.replace('.', ',') + "km"}</>
																: "distância não informada"}
													</div>
												</Col>
											</div>
											<div className="row my-1">
												<div className="col d-flex flex-column">
													{(cotacao['s.valor'] || cotacao['s.valorProfissional']) && <div className="mb-0 pb-0">
														<span style={{ fontSize: "1.75rem", color: "var(--bs-success)", fontWeight: 800 }}>
															{cotacao['s.valor'] == "-"
																? "-"
																: cotacao['s.valor']
																	? new Intl.NumberFormat('pt-BR', {
																		style: 'currency',
																		currency: 'BRL'
																	}).format(cotacao['s.valor'])
																	: new Intl.NumberFormat('pt-BR', {
																		style: 'currency',
																		currency: 'BRL'
																	}).format(cotacao['s.valorProfissional'])}
														</span>
													</div>}

													<div style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
														{cotacao["metodoPag"] && formaPg(cotacao["metodoPag"])}
													</div>
												</div>

												{cotacao.destaqueDistancia == "DV"
													? cotacao['sm.distanciaColeta'] && <div className="col text-end">
														<div style={{ fontSize: "1.75rem", fontWeight: 600 }}>
															{cotacao['sm.distanciaColeta'].replace('.', ',') + "km"}
														</div>
														<div className="text-end" style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
															Distância de você
														</div>
													</div>
													: <div className="col text-end">
														<div style={{ fontSize: "1.75rem", fontWeight: 600 }}>
															{cotacao['s.distancia'].replace('.', ',') + "km"}
														</div>
														<div className="text-end" style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
															Distância do serviço
														</div>
													</div>}
											</div>
											{cotacao.valorPorKm && <div className="small text-success">Valor por km: R$ {cotacao.valorPorKm}</div>}

											<div className="d-flex gap-1">
												{cotacao['s.dataHoraColetar'] && <Status
													danger={urgente}
													color="var(--bs-info)"
													agendado={cotacao['s.dataHoraColetar'] != "0000-00-00 00:00:00"}
													texto={
														<span style={{ fontSize: "1rem" }}>
															{cotacao['s.dataHoraColetar'] === "0000-00-00 00:00:00"
																? "AGORA"
																: format(parse(cotacao["s.dataHoraColetar"], 'yyyy-MM-dd HH:mm:ss', new Date()), "dd/MM HH:mm")}
														</span>
													} fill />}
												{cotacao["s.tipoVecDesc"] && <Status
													danger={urgente}
													color="var(--bs-info)"
													texto={<span>{cotacao["s.tipoVecDesc"]}</span>}
													fill
												/>}
											</div>

											<div class="row g-1 mt-0">
												{cotacao["so.nome"] && <Col className="text-truncate">
													<Status texto={cotacao["so.nome"]} truncate pop fill />
												</Col>}
												{cotacao["so.empresa"] && <Col className="text-truncate">
													<Status texto={cotacao["so.empresa"]} truncate pop fill />
												</Col>}
												{cotacao["so.nomeFantasia"] && <Col className="text-truncate">
													<Status texto={cotacao["so.nomeFantasia"]} truncate pop fill />
												</Col>}
											</div>

											{cotacao.enderecos && <div id={i} ref={i === 0 ? anchorRef : null} className="m-0 my-2 d-flex flex-column" onClick={() => {
												if (cotacao.enderecos) {
													setModal(true)
													setCotacaoSel(cotacao)
												}
											}}>
												<Row>
													<div style={{ width: '90%' }}>
														<Row>
															<Col className="d-inline-block" style={{
																color: "var(--primaria-darkest)",
																// textAlign: "justify",
																// textJustify: "inter-word",
																lineHeight: 1
															}}>
																<span style={{
																	hyphens: "auto",
																	webkitHyphens: "auto",
																	mozHyphens: "auto",
																	msHyphens: "auto"
																}}>
																	{cotacao.enderecos[0]['e.rua']}
																</span>
															</Col>
														</Row>

														<Row className="position-relative">
															{cotacao.enderecos?.length == 2
																? <Col
																	className="d-inline-block text-truncate mt-2 position-relative"
																	style={{ color: 'var(--primaria-darkest)' }}
																>
																	{cotacao.enderecos[1]['e.rua']}
																</Col>
																: <>
																	<Col xs="5" className="d-flex align-items-center opacity-50 text-truncate" style={{
																		color: 'var(--primaria-darkest)'
																	}}>
																		<div className="small d-flex align-items-center">
																			{(cotacao.enderecos?.length - 1) > 1 && <>
																				<Marcador>
																					+{cotacao.enderecos?.length - 1}
																				</Marcador>
																				Endereços
																			</>}

																		</div>
																	</Col>
																	<Col
																		xs="7"
																		className="d-flex align-items-center justify-content-end opacity-50"
																	>
																		{cotacao['s.retorno'] == 'S' && <div
																			style={{ color: 'var(--primaria-darkest)' }}
																			className="d-flex align-items-center gap-1 small">
																			<MdUndo size="22" />
																			Retorno
																		</div>}
																	</Col>
																</>}
														</Row>
													</div>
													<div style={{
														width: '10%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														paddingLeft: 0
													}}>
														<MdTouchApp size={22} />
													</div>
												</Row>
											</div>
											}

											<Popover
												open={i == 0 && open}
												anchorEl={anchor?.current}
												onClose={() => setOpen(false)}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'center',
												}}
												transformOrigin={{
													vertical: 'center',
													horizontal: 'center',
												}}
											>
												<Balloon icon={<MdTouchApp size={22} />}>
													Clique nos endereços para ver mais detalhes
												</Balloon>
											</Popover>

											<Row>
												<Col>
													<Row className="g-0">

														<Button
															color="success"
															startIcon={<MdCheck size={32} />}
															className="w-100"
															onClick={() => { confirmar(cotacao['s.id'], true) }}
														>
															Aceitar
														</Button>
													</Row>
												</Col>
												<Col>
													<Row className="g-0">
														<Button
															color="error"
															startIcon={<MdCancel size={32} />}
															className="w-100"
															onClick={() => { confirmar(cotacao['s.id'], false, cotacao['motivoRecusar']) }}
														>
															Recusar
														</Button>
													</Row>
												</Col>
											</Row>
										</Card>
									</Zoom>
								})
								}
							</>
						: <div className="position-relative">
							<Alert severity="error" className="mx-3 mt-4 text-center" action={<IconButton onClick={() => setCarregando(true)}>
								<Refresh />
							</IconButton>}>
								{msg}
							</Alert>

						</div>}
					{!carregando && !valido && <AlertDialog mensagem={{
						titulo: "Ative sua localização!",
						mensagem: "Sua localização está desativada e não será possível aceitar as cotações, ative sua localização para liberar o acesso.",
						opcao: "B"
					}} />}
				</div >}
			<MenuLateral show={menu} setShow={setMenu} ativo="0" />
		</motion.div >

		<Backdrop
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={modal}
			onClick={() => {
				setModal(false)
			}}
		>
			<Card className="p-3" style={{ width: '90vw', overflow: 'auto', maxHeight: '80vh' }}>
				<h3>#{cotacaoSel['s.id']}</h3>
				<h5 style={{ marginBottom: '-0.8rem' }}>Lista de endereços</h5>
				{Object.keys(cotacaoSel).length > 0 && cotacaoSel.enderecos.map(endereco => {
					return <CardEndereco
						id={endereco['es.ponto']}
						endereco={endereco['e.rua']}
						complemento={endereco['e.complemento']}
						contato={endereco['e.contato']}
						tel={endereco['e.telefone']}
						observacao={endereco['e.observacao'] ?? endereco['e.responsavel']}
						retorno={endereco['e.retorno']}
						recebimento={endereco['e.endPontoReceber']}
						icone
					/>
				})}

				{cotacaoSel['s.pontoReceber'] && <div className='mx-2'>
					<Card className='p-2 small text-white' style={{ backgroundColor: "var(--primaria-darker)" }}>
						<h4 className='text-center fw700'>Observação</h4>
						{cotacaoSel['s.pontoReceber']}
					</Card>
				</div>}
			</Card>
		</Backdrop>
		<Backdrop
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
			open={BD}
		>
			{BDLoading
				? <div>
					<Spinner />
				</div>
				: <Card className="p-3">
					<h2>Confirme sua solicitação</h2>
					<p className="text-center small">
						{texto}
					</p>
					{modo != 'S' && configRecusar.exibicao && <>
						<label htmlFor="motivo">
							Motivo
							{configRecusar.obrigatorio && <span className="red">
								*
							</span>}
						</label>
						<textarea
							id="motivo"
							value={motivo}
							onChange={handleMotivo}
							className="text-area"
							placeholder="Digite aqui um motivo de recusa"
						/>
					</>}
					<Row>
						<Col>
							<Button
								color="success"
								variant="contained"
								className="w-100"
								onClick={() => { responderCotacao(idAtivo, modo) }}
							>
								Confirmar
							</Button>
						</Col>
						<Col>
							<Button
								color="error"
								variant="text"
								className="w-100"
								onClick={() => {
									setMotivo('')
									setBD(false)
								}}
							>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Card>}
		</Backdrop>
		<FakeGPSBlock />
	</>
}

function DataHora({ data }) {
	try {
		const dataHora = new Date(data)
		return <div>
			{String(dataHora.getDate()).padStart(2, "0")}/{String(dataHora.getMonth() + 1).padStart(2, "0")} {String(dataHora.getHours()).padStart(2, "0")}:{String(dataHora.getMinutes()).padStart(2, "0")}
		</div>
	} catch {
		return <div>
			Data inválida
		</div>
	}
}

function CotacaoAceitar({ cotacao, time, confirmar, reload }) {
	const urgente = cotacao["s.urgente"] == "S"
	const economico = cotacao["s.freteExpresso"] == "S"

	const { faltante, limite } = time

	const [val, setVal] = useState((limite - faltante) / limite)
	const [IDTimer, setIDTimer] = useState(null)
	const [loading, setLoading] = useState(false)
	const i = 0
	const anchorRef = useRef()

	useEffect(() => {
		setVal(1)
		setIDTimer(setTimeout(() => {
			confirmar(cotacao['s.id'], "E")
			setTimeout(() => { reload() }, 1000)
		}, faltante * 1000))
	}, [])

	return <>
		{loading
			? <div className="vh-100">
				<Spinner />
			</div>
			: <div className="vh-100" style={{
				width: "100vw"
			}}>
				<div className="p-3">
					{urgente && <div className="bg-danger mb-2 pt-3 text-light" style={{
						margin: "-1rem",
						padding: "0.5rem 1rem"
					}}>
						<div className="d-flex align-items-center gap-2">
							<img src={icon} style={{
								height: "1.25rem",
								filter: "brightness(10)"
							}} alt="" />
							Serviço urgente
						</div>
					</div>}

					{economico && <div className="bg-info mb-2 pt-3 text-light" style={{
						margin: "-1rem",
						padding: "0.5rem 1rem"
					}}>
						<div className="d-flex align-items-center gap-2">
							<MdMonetizationOn size={24} />
							Serviço econômico
						</div>
					</div>}
					<div className="d-flex justify-content-between align-items-center">
						<Col sm={3}>
							<span style={{ fontWeight: 800, fontSize: "1.6rem", lineHeight: 1, color: urgente ? 'var(--bs-danger)' : economico ? "var(--bs-info)" : 'var(--primaria)' }}>
								#{cotacao['s.id']}
							</span>
						</Col>
						<Col sm={9} className="d-flex align-items-end flex-column" style={{
							lineHeight: 1,
							opacity: 0.75,
							fontWeight: 600,
							fontSize: "0.8rem",
							color: urgente ? 'var(--bs-danger)' : economico ? "var(--bs-info)" : 'var(--primaria-darkest)'
						}}>
							{cotacao["s.dataHora"] && <DataHora data={cotacao["s.dataHora"]} />}
							<div>
								{cotacao.destaqueDistancia == "DV"
									? cotacao.labelDistancia
										? cotacao['s.distancia']
											? <>{cotacao['labelDistancia']}: {cotacao['s.distancia']?.replace('.', ',')}km</>
											: "distância não informada"
										: cotacao['s.distancia']
											? <>percurso: {cotacao['s.distancia']?.replace('.', ',')}km</>
											: "distância não informada"
									: cotacao['sm.distanciaColeta']
										? <>Distância de você: {cotacao['sm.distanciaColeta']?.replace('.', ',') + "km"}</>
										: "distância não informada"}
							</div>
						</Col>
					</div>
					<div className="row my-1">
						<div className="col d-flex flex-column">
							{(cotacao['s.valor'] || cotacao['s.valorProfissional']) && <div className="mb-0 pb-0">
								<span style={{ fontSize: "2rem", color: "var(--bs-success)", fontWeight: 800 }}>
									{cotacao['s.valor'] == "-"
										? "-"
										: cotacao['s.valor']
											? new Intl.NumberFormat('pt-BR', {
												style: 'currency',
												currency: 'BRL'
											}).format(cotacao['s.valor'])
											: new Intl.NumberFormat('pt-BR', {
												style: 'currency',
												currency: 'BRL'
											}).format(cotacao['s.valorProfissional'])}
								</span>
							</div>}

							<div style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
								{cotacao["metodoPag"] && formaPg(cotacao["metodoPag"])}
							</div>
						</div>

						{cotacao.destaqueDistancia == "DV"
							? cotacao['sm.distanciaColeta'] && <div className="col text-end">
								<div style={{ fontSize: "1.75rem", fontWeight: 600 }}>
									{cotacao['sm.distanciaColeta'].replace('.', ',') + "km"}
								</div>
								<div className="text-end" style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
									Distância de você
								</div>
							</div>
							: <div className="col text-end">
								<div style={{ fontSize: "1.75rem", fontWeight: 600 }}>
									{cotacao['s.distancia'].replace('.', ',') + "km"}
								</div>
								<div className="text-end" style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
									Distância do serviço
								</div>
							</div>}
					</div>

					{cotacao.valorPorKm && <div className="small text-success">R$ {cotacao.valorPorKm}/km</div>}

					<div className="d-flex gap-1">
						{cotacao['s.dataHoraColetar'] && <Status
							danger={urgente}
							color="var(--bs-info)"
							agendado={cotacao['s.dataHoraColetar'] != "0000-00-00 00:00:00"}
							texto={
								<span style={{ fontSize: "1rem" }}>
									{cotacao['s.dataHoraColetar'] === "0000-00-00 00:00:00"
										? "AGORA"
										: format(parse(cotacao["s.dataHoraColetar"], 'yyyy-MM-dd HH:mm:ss', new Date()), "dd/MM HH:mm")}
								</span>
							} fill />}
						{cotacao["s.tipoVecDesc"] && <Status
							danger={urgente}
							color="var(--bs-info)"
							texto={<span>{cotacao["s.tipoVecDesc"]}</span>}
							fill
						/>}
					</div>

					<div class="row g-1 mt-0">
						{cotacao["so.nome"] && <Col className="text-truncate">
							<Status texto={cotacao["so.nome"]} truncate pop fill />
						</Col>}
						{cotacao["so.empresa"] && <Col className="text-truncate">
							<Status texto={cotacao["so.empresa"]} truncate pop fill />
						</Col>}
						{cotacao["so.nomeFantasia"] && <Col className="text-truncate">
							<Status texto={cotacao["so.nomeFantasia"]} truncate pop fill />
						</Col>}
					</div>

					{cotacao.enderecos && <div id={i} ref={i === 0 ? anchorRef : null} className="d-flex flex-column">
						{Object.keys(cotacao).length > 0 && cotacao.enderecos.map(endereco => {
							return <CardEndereco
								id={endereco['es.ponto']}
								endereco={endereco['e.rua']}
								complemento={endereco['e.complemento']}
								contato={endereco['e.contato']}
								tel={endereco['e.telefone']}
								observacao={endereco['e.observacao'] ?? endereco['e.responsavel']}
								retorno={endereco['e.retorno']}
								recebimento={endereco['e.endPontoReceber']}
								icone
							/>
						})}

						{cotacao['s.pontoReceber'] && <div className='mx-2'>
							<Card className='p-2 small text-white' style={{ backgroundColor: "var(--primaria-darker)" }}>
								<h4 className='text-center fw700'>Observação</h4>
								{cotacao['s.pontoReceber']}
							</Card>
						</div>}
					</div>}
				</div>
				<div style={{
					position: "sticky",
					background: "white",
					width: "100vw",
					bottom: 0
				}}>
					<Row>
						<Col>
							<Row className="g-0">
								<Button
									color="success"
									startIcon={<MdCheck size={32} />}
									className="w-100"
									onClick={() => {
										setLoading(true)
										if (IDTimer) {
											clearTimeout(IDTimer)
											setIDTimer(null)
										}
										confirmar(cotacao['s.id'], true)
									}}
								>
									Aceitar
								</Button>
							</Row>
						</Col>
						<Col>
							<Row className="g-0">
								<Button
									color="error"
									startIcon={<MdCancel size={32} />}
									className="w-100"
									onClick={() => {
										setLoading(true)
										if (IDTimer) {
											clearTimeout(IDTimer)
											setIDTimer(null)
										}
										confirmar(cotacao['s.id'], false, cotacao['motivoRecusar'])
									}}
								>
									Recusar
								</Button>
							</Row>
						</Col>
					</Row>
					<div className="position-relative">
						<div
							style={{
								background: "var(--primaria)",
								width: (val * 100) + "%",
								transition: (faltante * 1000) + "ms linear",
								display: "block",
								height: "8px",
								color: "transparent"
							}}
						/>
					</div>
					<Counter initTime={faltante} desc />
				</div>
			</div>}
	</>
}

function Counter({ initTime, desc }) {
	const [time, setTime] = useState(initTime)
	useEffect(() => {
		if (time > 0) {
			const id = setTimeout(() => setTime(prev => desc ? prev - 1 : prev + 1), 1000)
			return () => clearTimeout(id)
		}
	}, [time])


	return <div className="text-center small" style={{
		color: "var(--primaria)",
		animation: "timer 1s infinite",
		fontSize: "2.2rem",
		fontWeight: 800
	}}>
		{time}
	</div>
}
