import { Popover } from "@mui/material"
import { useState } from "react"
import styled from "styled-components"

const Div = styled.div`
    width: 100%;
    height: 1.8rem;
    font-weight: 800;
    border: 2px solid ${props => (props.fill ? 'transparent' : props.color)};
    font-size:  0.85rem;
    border-radius: 0.5rem;
    color: ${props => (props.fill ? 'white' : props.color)};
    padding: 0px 0.5rem;
    background-color: ${props => (props.fill ? props.color : 'transparent')};
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function Status(props) {
    const [anchor, setAnchor] = useState(null)


    const handleClick = (event) => {
        // event.preventDefault()
        // event.stopPropagation()

        setAnchor(event.currentTarget)
    }

    const open = Boolean(anchor);

    return <>
        <Div
            fill={props?.fill ? 1 : 0}
            className="text-truncate"
            onClick={handleClick}
            color={props.agendado
                ? "#da7109"
                : props.danger
                    ? "#dc3545"
                    : props?.color
                        ? props.color
                        : 'var(--primaria)'}
        >
            {props.truncate
                ? <div className="text-truncate" style={{
                    color: props.black && props.color && "#000000BB"
                }}>
                    {props.texto}
                </div>
                : <div className="text-wrap lh-1">{props.texto}</div>}
        </Div>
        {props.pop && <Popover
            open={open}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <div className="p-2">
                {props.texto}
            </div>
        </Popover>}
    </>
}