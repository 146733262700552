import { motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { configAnim } from "../../../servicos/config/configAnim"
import ProfissionalAPI from "../../../servicos/ProfissionalAPI"
import MensagemApp from "../../../servicos/config/MensagemApp"
import Refresher from "../../../componentes/uteis/Refresher"
import { Alert } from "@mui/material"
import { Spinner } from "../../../componentes/Spinner"

export default function TermosUso() {
    const [menu, setMenu] = useState(false)
    const [carregando, setCarregando] = useState(true)
    const [link, setLink] = useState('')
    const [erroAPI, setErroAPI] = useState(false)
    const [msgRetorno, setMsgRetorno] = useState('')

    const { dados } = useContext(ContextoGeral)

    async function buscarTermosUso() {
        let retorno = await ProfissionalAPI.buscarTermosUso(dados)
        setErroAPI(false)
        if (retorno?.resultado) {
            if (retorno.dados != '') {
             setLink(retorno.dados.link)
                //setErroAPI(true)
                //setMsgRetorno('Termos de uso não configurados!')
            } else {
                setErroAPI(true)
                setMsgRetorno('Termos de uso não configurados!')
            }
        } else {
            setErroAPI(true)
            setMsgRetorno('Não foi possível buscar os termos de uso, tente mais tarde.')
        }
        setCarregando(false)
    }

    useEffect(() => {
        if (carregando) {
            buscarTermosUso()
        }
    }, [carregando])

    return (
        <motion.div
            className="telaAjustada"
            initial={configAnim.initial}
            animate={configAnim.animate}
            exit={configAnim.exit}
            transition={configAnim.transition}
        >
            <TopoMenu voltar="/app/configuracoes" titulo="TERMOS DE USO" setShow={setMenu} />
            {carregando ? (
                <div style={{ height: 'calc(100vh - 3rem)' }}>
                    <Spinner />
                </div>
            ) : (
                <>
                    {erroAPI ? (<div>
                        <div className="mx-2">
                            <Alert severity="error" className="text-center mt-4">
                                {msgRetorno}
                            </Alert>
                            <Refresher
                                setCarregando={setCarregando}
                            />
                        </div>
                    </div>) : (<>
                        <div>
                            {link != '' &&
                                <iframe
                                    style={{
                                        height: 'calc(100vh - 3rem)',
                                        width: '100%'
                                    }}
                                    src={`${link}?exibirBtn=false`} title="política de privacidade"
                                />
                            }
                        </div>
                    </>)}

                </>
            )}
            <footer></footer>
            <MenuLateral show={menu} setShow={setMenu} ativo="4" />
        </motion.div>
    )
}