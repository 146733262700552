
const MensagemApp = {
    erroAssinatura: 'Favor assine no espaço designado',
    erroPaineis: 'Não foi possível carregar todos os componentes desta página, porém você poderá prosseguir normalmente clicando nos botões abaixo',
    funcIndisponivel: 'Esta funcionalidade não está disponível nesta versão do aplicativo',
    msgErroApi: 'Não foi possível se conectar, favor verifique sua rede!',
    msgErroLaLo: 'Ative sua localização para prosseguir!',
    msgErroSistemaCamera: 'Captura cancelada pelo usuário.',
    msgErroLista: 'Houveram os seguintes erros:',
    timeout: 'Tempo excedido, atualize sua tela para confirmar se sua solicitação foi concluída ou tente novamente.',
    msgDesconectado: 'Você está desconectado, favor entre novamente!',
    deslogado: 'Você está offline!',
    msgSucessoLogar: 'Sucesso!',
    msgErroCotacao: 'Você não está online, por isso não pode aceitar um serviço',
    msgPermissaoCamera: 'Este aplicativo precisa da permissão de camera ativado.',
    msgPermissaoArmazenamento: 'Este aplicativo necessita de acesso de arquivos de midia',
    msgPermissaoGPS: 'Este aplicativo precisa da permissão de localização ativado.',
    msgPermissaoNotificacao: 'As notificações estão desativadas, por favor, ative-as novamente para prosseguir.',
    msgOtimizacaoBateria: 'A otimização de bateria está habilitado para esse app, por favor, remover para nenhuma restrição.',
    msgHabilitarGPS: 'Este aplicativo precisa do GPS  ativado.',
    msgSucessoProf: 'Mensagem enviada com sucesso',
    tituloFakeGps : 'Permissão de localização fictícia',
    msgFakeGPSBloqueio : 'Seu celular está com a permissão de localização fictícia ativa. Isso implica que pode ter em seu celular algum aplicativo que altera sua localização. Favor remover esta permissão para usar este aplicativo.',
    msgFakeGPSInf : 'Seu celular está com a permissão de localização fictícia ativa. Isso implica que pode ter em seu celular algum aplicativo que altera sua localização. Favor remover esta permissão. Em um futuro breve se não remover esta permissão não será possível continuar utilizando este aplicativo',
    tituloBootClick : 'Permissão acessibilidade',
    msgBootClickBloqueio : 'Seu celular está com a permissão de Acessibilidade ativa. Isso implica que pode ter em seu celular algum aplicativo que automatiza ações neste aplicativo. Favor remover esta permissão',
    msgBootClickInf : 'Seu celular está com a permissão de Acessibilidade ativa. Isso implica que pode ter em seu celular algum aplicativo que automatiza ações neste aplicativo. Favor remover esta permissão. Em um futuro breve se não remover esta permissão não será possível continuar utilizando este aplicativo.'
}

export default MensagemApp