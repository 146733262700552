import axios from "axios"

export default class APISemNode {
    static async requisicaoPOST(dominio, dados, rota, token) {

        return await axios({
            url: `${dominio}/${rota}`,
            method: 'POST',
            metodo: 'POST',
            headers: {
                // "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify(dados, null, 3),
            timeout: 30000
        }).then(res => res.data).catch(rej => rej)
    }

    static async requisicaoPUT(dominio, dados, rota, token) {

        return await axios({
            url: `${dominio}/${rota}`,
            method: 'PUT',
            metodo: 'PUT',
            headers: {
                // "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify(dados, null, 3),
            timeout: 30000
        }).then(res => res.data).catch(rej => rej)
    }

    static async requisicaoGET(dominio, dados, rota, token) {
        return await axios({
            url: `${dominio}/${rota}?${dados}`,
            // params: dados,
            method: 'GET',
            metodo: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            timeout: 30000
        }).then(res => res.data).catch(rej => rej)
    }
}