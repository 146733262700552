import { format } from "date-fns/esm"
import { motion } from "framer-motion"
import { useContext, useEffect } from "react"
import { useState } from "react"
import Escritor from "../../../componentes/form/Escritor"
import ListaMensagens from "./ListaMensagens"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { Spinner } from "../../../componentes/Spinner"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { configAnim } from "../../../servicos/config/configAnim"
import MensagemAPI from "../../../servicos/MensagemAPI"
import MensagemApp from "../../../servicos/config/MensagemApp"
import Refresher from "../../../componentes/uteis/Refresher"
import { Alert } from "@mui/material"
import { useRef } from "react"

export default function Mensagens() {
	const [carregando, setCarregando] = useState(true)
	const [menu, setMenu] = useState(false)
	const [alerta, setAlerta] = useState(false)
	const [msg, setMsg] = useState('')

	const [mensagens, setMensagens] = useState([])
	const [enviando, setEnviando] = useState(false)
	const [novaMsg, setNovaMsg] = useState('')
	const [novaMsgCounter, setNovaMsgCounter] = useState(0)
	const [msgSucesso, setMsgSucesso] = useState(false)

	const [ultimoId, setUltimoId] = useState(0)
	const [erroAPI, setErroAPI] = useState(false)

	const [novasMensagens, setNovasMensagens] = useState({ sucesso: false })

	const [spinner, setSpinner] = useState(false)

	const { dados, setDados, deslogar } = useContext(ContextoGeral)

	const [msgErro, setErro] = useState('')

	const refNovasMsgs = useRef([])

	useEffect(() => {
		if (typeof window.AndroidInterface !== 'undefined')
			window.AndroidInterface.removeNotification(523)
	}, [])

	useEffect(() => {
		if (carregando) buscarMensagens()
	}, [carregando])

	useEffect(() => {
		if (msgSucesso) {
			setEnviando(false)

			if (!!mensagens) {
				mensagens[0].blocos[0].mensagens.map(mensagem => {
					if (mensagem?.pendente) {
						mensagem.pendente = false
					}
				})
			}
			setMsgSucesso(false)
		}
	}, [msgSucesso])

	async function buscarMensagens() {
		let params = { idProf: dados.idProf }
		const retorno = await MensagemAPI.buscarMensagens(dados, { idProf: dados.idProf })

		if (retorno.resultado) {
			if (retorno.aux?.ultimoId) setUltimoId(retorno.aux.ultimoId)

			if (retorno.dados) {
				if (retorno.dados?.datas)
					setMensagens(retorno.dados.datas)
				if (retorno.dados.datasNaoLidas && retorno.dados.datasNaoLidas.length > 0)
					setNovasMensagens({ conteudo: retorno.dados.datasNaoLidas })
				else
					setNovasMensagens({ mensagem: "Nenhuma nova mensagem" })
			}

			setErroAPI(false)
		}
		setCarregando(false)
	}

	const enviar = async () => {
		if (novaMsg != '') {
			setNovasMensagens({
				sucesso: true
			})

			setEnviando(true)
			setNovaMsgCounter(novaMsgCounter + 1)

			let novaData = format(new Date(), 'yyyy-MM-dd')
			let novaDataHora = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

			if (mensagens && mensagens?.[0]?.data !== novaData)
				setMensagens([
					{
						key: 'data-0',
						data: novaData,
						blocos: [
							{
								key: "bloco-0",
								autor: "prof",
								mensagens: [
									{
										"key": novaDataHora,
										"identificador": "prof",
										"mensagem": novaMsg,
										"data": novaDataHora,
										"pendente": true
									}
								]
							}
						]
					},
					...mensagens
				])
			else {
				if (!!novasMensagens.conteudo && novasMensagens?.conteudo.length > 0) {
					let json = {}
					let msgs = novasMensagens

					if (novasMensagens.conteudo[0].blocos[0].autor == 'prof') {
						json = {
							key: novaDataHora,
							identificador: 'prof',
							data: novaDataHora,
							mensagem: novaMsg
						}

						msgs.conteudo[0].blocos[0].mensagens.unshift(json)
					} else {
						json = {
							key: 'bloco-1',
							autor: 'prof',
							mensagens: [{
								key: novaDataHora,
								identificador: 'prof',
								data: novaDataHora,
								mensagem: novaMsg
							}]
						}

						msgs.conteudo[0].blocos.unshift(json)
					}

					setNovasMensagens(msgs)
				} else if (mensagens.datas) {
					const CURRENT_DATAS = mensagens.datas
					const CURRENT_DATA = CURRENT_DATAS.shift()

					const FIRST_BLOCK = CURRENT_DATA.blocos[0]

					if (FIRST_BLOCK.autor == "prof") {
						let novaLista = FIRST_BLOCK.mensagens.map((msg, i) => {
							return {
								...msg,
								key: 'mensagem-' + (i + 1)
							}
						})

						novaLista.unshift({
							"key": novaDataHora,
							"identificador": "prof",
							"mensagem": novaMsg,
							"data": novaDataHora,
							"pendente": true
						})

						FIRST_BLOCK.mensagens = novaLista
					} else {
						CURRENT_DATA.blocos.unshift({
							key: "bloco-x-0",
							autor: "prof",
							mensagens: [
								{
									"key": novaDataHora,
									"identificador": "prof",
									"mensagem": novaMsg,
									"data": novaDataHora,
									"pendente": true
								}
							]
						})
					}

					setMensagens([
						CURRENT_DATA,
						...CURRENT_DATAS
					])
				}
			}

			refNovasMsgs.current[novaDataHora] = true
			let retorno = await MensagemAPI.enviar(dados, novaMsg).finally(() => setCarregando(true))

			if (retorno?.resultado) {
				setMsgSucesso(true)
				if (!!window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion(MensagemApp.msgSucessoProf)
			} else if (retorno?.codigo == 401 || retorno?.codigo == 401)
				if (!!window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion(retorno.mensagem)
				else if (!!window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)

			setNovaMsg('')
		}
	}

	const carregarMaisMsg = async () => {
		setSpinner(true)
		setErroAPI(false)

		let params = { idProf: dados.idProf }
		if (ultimoId > 0) params.ultimoId = ultimoId

		let retorno = await MensagemAPI.buscarMensagens(dados, params)

		if (retorno?.resultado) {
			if (retorno.aux?.ultimoId) {
				setUltimoId(retorno.aux.ultimoId)
			}

			if (retorno.dados != '') {
				console.log(mensagens)
				let lista = mensagens.concat(retorno.dados.datas)
				setMensagens(lista)
			} else {
				setAlerta(true)
				setMsg(retorno.mensagem)
			}
		} else {
			setErroAPI(true)
		}

		setSpinner(false)
	}

	const alterarConteudo = event => setNovaMsg(event.target.value)


	return <motion.div
		className="telaAjustada"
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
	>
		<TopoMenu voltar="/app/home" titulo={"MENSAGENS"} setShow={setMenu} />
		{carregando
			? <div className="corpo">
				<Spinner />
			</div>
			: <>
				{erroAPI ? <div className="mx-2">
					<Refresher
						setCarregando={setCarregando}
					/>
					<Alert severity="error" className="text-center mt-4">
						{msgErro}
					</Alert>
				</div>
					: <ListaMensagens
						mensagens={mensagens}
						novasMensagens={novasMensagens}
						novasMensagensRef={refNovasMsgs}
						atualizar={carregarMaisMsg}
						spinner={spinner}
						alerta={alerta}
						msgAlerta={msg}
					/>}
			</>}
		<Escritor fn={enviar} conteudo={novaMsg} alterarConteudo={alterarConteudo} />
		<MenuLateral show={menu} setShow={setMenu} ativo="0" />
	</motion.div>
}