import { useEffect, useState, useContext } from "react";
import { ContextoGeral } from "../../../../contextos/ContextoGeral";

export default function useConexaoSocket(dados, executarHook, setExecutarHook) {

    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);
    const [msg, setMsg] = useState("");
    const [nomeSala, setNomeSala] = useState('');
    const [userId, setUserId] = useState('');

    const { websocket } = useContext(ContextoGeral);

    useEffect(() => {

        if (dados?.room) {
            setNomeSala(dados?.room);
        } else {
            setNomeSala(dados?.nomeSala);
        }

        setUserId(dados?.idProf);

    }, [dados]);

    // Criar a conexão do WebSocket quando o target mudar
    useEffect(() => {

        let target = 'central';
        if (nomeSala) {
            const tipoSala = nomeSala.match(/^[A-Za-z]+/);

            if (tipoSala[0] == 'E') {
                target = 'cliente'
            }
        }

        if (websocket) {
            // Emite o evento "create-room" para o servidor
            websocket.emit("create-room", { nomeSala, idConfig: dados?.idConfig, target, userId });

            setConnected(true);
        }

        // Salvar o socket na variável de estado
        setSocket(websocket);

        // Fechar a conexão ao desmontar o componente
        return () => {

            if (websocket) {
                websocket.emit('leave-room', { nomeSala, userId, idConfig: dados?.idConfig });
                // console.log('Saiu da sala');
            }
            setConnected(false);
        };

    }, [nomeSala]);

    return { socket, connected, msg };
}
