import { CameraAlt, Close, Collections } from "@mui/icons-material"
import { Alert, Button, Card, CircularProgress, IconButton } from "@mui/material"
import { useState } from "react"
import CheckPermission from "../../servicos/config/CheckPermission"
import useCamera from "../../hooks/general/useCamera"
import useGallery from "../../hooks/general/useGallery"

export function ProtocolContainer({ opcao, handleClose, reload, handleDB, onObsolete }) {
	const [open, setOpen] = useState(false)
	const [openGallery, setOpenGallery] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")

	async function handleCamera() {
		if (await CheckPermission.checkCamera()) {
			if (window.AndroidInterface?.recoverVersion() >= 102.26) {
				setOpen(true)
			} else {
				onObsolete()
			}
		} else try {
			window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera)
		} catch {
			alert("É necessário ativar a permissão de câmera neste aplicativo!")
		}
	}

	async function handleGallery() {
		setOpenGallery(true)
	}

	return <Card className="m-3 p-3 position-relative">
		<IconButton disabled={open || openGallery} className="position-absolute end-0 top-0" onClick={handleClose}>
			<Close />
		</IconButton>
		<h4 className="my-3">Qual forma deseja buscar sua imagem de protocolo?</h4>
		<div className="d-flex gap-3">
			{opcao != "G" && window.AndroidInterface && <Button
				variant="contained"
				className="mt-3"
				disabled={open || openGallery}
				startIcon={<CameraAlt />}
				onClick={handleCamera}
				fullWidth
			>
				Câmera
			</Button>}
			{opcao != "C" && <Button
				variant="contained"
				className="mt-3"
				disabled={open || openGallery}
				onClick={handleGallery}
				startIcon={<Collections />}
				fullWidth
			>
				Galeria
			</Button>}
		</div>

		{open && <ProtocolCamera
			onFinish={async (URL) => {
				setErrorMsg("")
				await handleDB(URL)
				setOpen(false)
				reload()
			}}
			onError={msg => {
				setErrorMsg(msg)
				setOpen(false)
			}}
		/>}

		{openGallery && <ProtocolGallery
			onFinish={async (URL) => {
				setErrorMsg("")
				await handleDB(URL)
				setOpenGallery(false)
				reload()
			}}
			onError={msg => {
				setErrorMsg(msg)
				setOpenGallery(false)
			}}
		/>}

		{!open && !openGallery && errorMsg && errorMsg.length > 0 && <Alert severity="error" className="mt-3" icon={false}>
			{errorMsg}
		</Alert>}
	</Card>
}

function ProtocolCamera({ onFinish, onError }) {
	useCamera(onFinish, onError)

	return <div className="text-center mt-3">
		<CircularProgress />
	</div>
}

function ProtocolGallery({ onFinish, onError }) {
	useGallery(onFinish, onError)

	return <div className="text-center mt-3">
		<CircularProgress />
	</div>
}