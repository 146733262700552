import { Collapse } from "@mui/material"
import styled from "styled-components"
import Badge from './uteis/Badge'

const Btn = styled.button`
    height: ${props => (props.height ?? '54px')};
    width: ${props => (props.width ?? '54px')};
    border-radius:50%;
    background-color: ${props => props.pura == true ? 'var(--primaria-pura)' : '#ffffff'};
    margin: 0.5rem;
    box-shadow: 0px 2px 7px 2px #00000033;
    color: var(--primaria-darkest);
    border: 0;

    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: 0.1s;

    & svg {
        font-size: 2rem;
        transition: 0.1s;
    }

    &:active {
        box-shadow: 0 0 10px 5px #fff;
        background-color: var(--primaria);
        color: #fff;
        transition: 0.1s;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
        background-color: var(--grey-600);
    }
`

export function BtnRedondo(props) {
    return (
        <Collapse in={props.collapse ?? true}>
            <Btn
                disabled={props.disabled}
                height={props.height}
                width={props.width}
                onClick={props.onClick}
                pura={Boolean(props.pura)}
            >
                {props.children}
                {props.badge !== undefined && props.badge != '' ? (
                    <Badge valor={props.badge} />
                ) : ("")}
            </Btn>
        </Collapse>
    )
}