import { useState, useEffect } from 'react';

const useNomeSala = (location, dados) => {

    const [quemConversar, setQuemConversar] = useState('');
    const [nomeSala, setNomeSala] = useState('');
    const [voltar, setVoltar] = useState('/app/home');

    useEffect(() => {

        if (location?.state?.conversa === 'central') {
            if (dados?.quemConversar && !quemConversar) {
                setQuemConversar(dados?.quemConversar)
            }
        } else {

            // Atualiza quem está conversando com base no estado de 'location'
            if (location?.state?.conversa === 'cliente') {
                setQuemConversar('cliente');
                setVoltar(`/app/servicos/${location?.state?.idServico}`)
            } else {
                setQuemConversar('central');
            }
        }
    }, [location?.state]);

    useEffect(() => {
        // Define o nome da sala baseado em quem está conversando

        if (quemConversar === 'central') {
            if (!nomeSala) {
                if (dados?.nomeSala) {
                    setNomeSala(dados?.nomeSala);
                } else {
                    if (dados?.idProf) {
                        setNomeSala("P" + dados?.idProf);
                    }
                }
            }
        } else if (quemConversar === 'cliente') {
            setNomeSala("E" + location?.state?.idServico);
        }

    }, [quemConversar, location?.state, dados]);

    // Retorna quem está conversando e o nome da sala
    return { quemConversar, nomeSala, voltar };
};

export default useNomeSala;