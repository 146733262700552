import ServicoAPI from "./../../../servicos/ServicoAPI"

import { Alert, CircularProgress, TabPanel, Collapse, Tab, Tabs, Zoom, Badge, Button } from "@mui/material"
import { motion } from "framer-motion"
import { useState } from "react"
import { TabPane } from "react-bootstrap"

import TopoMenu from "../../../componentes/gerais/TopoMenu"
import Filtro from "../../../componentes/uteis/Filtro"
import MenuLateral from "../../../componentes/gerais/Menu"
import { useContext } from "react"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { useEffect } from "react"
import { Spinner } from "../../../componentes/Spinner"
import { configAnim } from "../../../servicos/config/configAnim"

import CardServico from "./../../../componentes/servicos/cardServico"
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import Refresher from "../../../componentes/uteis/Refresher"
import { verificarFakeGPS, verificarBootClick } from '../../../servicos/Uteis.js'
import AlertDialog, { BootClickAlert, FakeGpsAlert } from "./../../../componentes/gerais/Dialog.jsx"
import { Link, useLocation, useNavigate } from "react-router-dom"
import FakeGPSBlock from "../../../componentes/uteis/FakeGPSBlock"
import CardAcertado from "../../../componentes/servicos/CardAcertado.jsx"

export default function Servicos() {
	const navigate = useNavigate()

	const [exibirFakeGPS, setExibirFakeGPS] = useState(false)
	const [exibirBootClick, setExibirBootClick] = useState(false)

	const [servicos, setServicos] = useState({})
	const [menu, setMenu] = useState(false)
	const [block, setBlock] = useState(false)

	// MOSTRA TABS PARA CLIENTE FARMÁCIA
	const [tab, setTab] = useState(0)
	const [acertar, setAcertar] = useState(0)
	const [exibir, setExibir] = useState(false)
	const [corDinamica, setCorDinamica] = useState(false)

	// BOTCLICK & FAKE GPS
	const [idServico, setIdServico] = useState()

	const [filtro, setFiltro] = useState('')
	const [msg, setMsg] = useState(<div>
		<CircularProgress size={20} />
	</div>)
	const [countServicos, setCountServicos] = useState(0)

	const [carregando, setCarregando] = useState(true)
	const [mostrarFiltro, setMostrarFiltro] = useState(false)
	const [valido, setValido] = useState(false)

	const { dados, deslogar } = useContext(ContextoGeral)

	async function buscarQuantAcertar() {
		ServicoAPI.buscarQuantAcertar(dados).then(res => {
			if (res.resultado)
				if (res.dados) {
					setAcertar(res.dados.quant)
				}
		}).catch(rej => {
			console.log(rej)
		})
	}

	async function buscarServicos() {
		if (dados.statusOnline) {
			let retorno = await ServicoAPI.buscarServicos(dados)
			if (retorno?.resultado) {
				if (retorno?.aux?.dadosProf)
					setExibir(retorno?.aux?.dadosProf?.exibeBotaoAcertar == "S")
				else
					setExibir(retorno?.aux?.exibeBotaoAcertar == "S")

				setCorDinamica(retorno?.aux?.dadosProf?.corDinamica == "S")

				if (retorno?.erro) setMsg(retorno.erro)
				else {
					setValido(true)
					if (retorno?.resultado) {
						setServicos(retorno)
						setMsg(retorno.mensagem)

						if (retorno.aux) setMostrarFiltro(true)
					} else if (retorno?.mensagem) {
						setMsg(retorno.mensagem)

						if (typeof window.AndroidInterface !== 'undefined')
							window.AndroidInterface.showAndroidVersion(retorno.mensagem)
					} else setMsg(MensagemApp.msgErroApi)
				}

			} else {
				if (retorno?.aux == 'tokenInvalido') deslogar()

				if (retorno?.msg)
					setMsg(retorno?.msg)
				else
					setMsg(MensagemApp.msgErroApi)
			}
		} else setBlock(true)

		setCarregando(false)
	}

	function filtrarServicos(filtro) {
		setFiltro(filtro)
	}

	const acessarServico = async (id) => {
		try {
			setIdServico(id)

			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface?.recoverVersion() > 100.80) {
				let exibir = false

				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					exibir = true
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)
					exibir = true
				}

				if (!exibir)
					navigate(`/app/servicos/${id}`, {
						state: {
							idServico: id
						}
					})
			} else navigate(`/app/servicos/${id}`, {
				state: {
					idServico: id
				}
			})

		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (typeof window.AndroidInterface !== 'undefined')
			window.AndroidInterface.removeNotification(673)
	}, [])

	useEffect(() => {
		if (exibir) buscarQuantAcertar()
	}, [exibir])

	async function verificarAntifraude() {
		try {
			if (window.AndroidInterface?.recoverVersion && window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)
				}
			}
		} catch {
			console.error(MensagemApp.funcIndisponivel)
		}
	}

	useEffect(() => {
		verificarAntifraude()
		// buscarQuantAcertar()
	}, [])

	useEffect(() => {
		buscarServicos()
	}, [carregando])

	useEffect(() => {
		if (servicos.dados !== undefined)
			setCountServicos(servicos.dados.length)
	}, [servicos])

	return <motion.div
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
	>
		{
			carregando
				? <div className="vh-100">
					<Spinner />
				</div>
				: <>
					<TopoMenu
						voltar="/app/home"
						titulo={`SERVIÇOS ABERTOS - ${countServicos}`}
						setShow={setMenu}
					/>

					<FakeGpsAlert
						open={exibirFakeGPS}
						setOpen={setExibirFakeGPS}
						opcao={dados?.configFakeGPS?.opcao}
						onClose={idServico ? () => {
							if (!exibirBootClick)
								navigate(`/app/servicos/${idServico}`)
						} : null}
					/>

					<BootClickAlert
						open={exibirBootClick}
						setOpen={setExibirBootClick}
						opcao={dados?.configBootClick?.opcao}
						onClose={idServico ? () => {
							if (!exibirFakeGPS)
								navigate(`/app/servicos/${idServico}`)
						} : null}
					/>
					{block
						? <div>
							<Alert className="m-3" variant="filled" color="error">
								Fique online para visualizar os serviços desta tela!
							</Alert>
						</div>
						: <div>
							<Collapse in={exibir}>
								<Tabs className="w-100" value={tab} onChange={(event, newValue) => setTab(newValue)}>
									<Tab className="w-50" value={0} label="Normais" />

									<Tab className="w-50" value={1} label={
										<Badge badgeContent={acertar} color="error">
											<div className="pe-3">Acertar</div>
										</Badge>} />
								</Tabs>
							</Collapse>

							{tab == 0 && <div className="w-100">
								{servicos?.dados && servicos.dados.length > 0
									? <div>
										<Collapse in={mostrarFiltro && Object.entries(servicos.aux).length > 1}>
											<Tabs variant="pills" className="mx-3 my-2" >
												{Object.entries(servicos.aux).map(item => {
													if (item[0] == 'dadosProf')
														return ''

													return <TabPane
														key={item[1].label}
														eventKey={item[1].label}
														title={<Filtro
															valor={item[1].qtde}
															fun={() => { filtrarServicos(item[0]) }}
														>{item[1].label}</Filtro>}
													/>
												})}
											</Tabs>
										</Collapse>

										<div className="w-100 pb-3">
											{servicos.dados.map((servico, i) => {
												return <Zoom
													key={servico['s.id']}
													in={filtro != '' ? servico.filtro == filtro : true}
													style={{
														transitionDelay: i * (800 / servicos.dados.length)
													}}
												>
													<div className="mx-3 mt-2" onClick={() => acessarServico(servico['s.id'])}>
														<CardServico
															servico={servico}
															corDinamica={corDinamica}
															mostrarNomes
														/>
													</div>
												</Zoom>
											}
											)}
										</div>
									</div>
									: <>
										<Alert severity="error" className="mx-3 mt-4 text-center" action={
											<>{valido && <Refresher setCarregando={setCarregando} />}</>
										}>
											{msg}
										</Alert>
									</>}
							</div>}

							{tab == 1 && <ListaServicosAcertar />}

							<MenuLateral show={menu} setShow={setMenu} ativo="0" />
							<FakeGPSBlock />
						</div>}
				</>
		}
	</motion.div >
}

function ListaServicosAcertar() {
	const { dados, deslogar } = useContext(ContextoGeral)

	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [servicos, setServicos] = useState([])

	async function buscarServicos() {
		if (dados.statusOnline) {
			await ServicoAPI.buscarServicosAcertar(dados).then(res => {
				console.log(res)
				if (res.resultado)
					setServicos(res.dados)

			}).catch(rej => {

			}).finally(() => setLoading(false))
		}
	}

	async function acessarServico(id) {
		try {
			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface?.recoverVersion() > 100.80) {
				let exibir = false

				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					exibir = true
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)
					exibir = true
				}

				if (!exibir)
					navigate(`/app/servicos/${id}`)
			} else navigate(`/app/servicos/${id}`)

		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		buscarServicos()
	}, [])

	return <>
		{loading
			? <div className="p-3 text-center">
				<CircularProgress />
			</div>
			: <div className="p-3">
				{servicos.length > 0
					? servicos.map((servico, i) => {
						return <Zoom
							in
							key={servico['s.id']}
							style={{
								transitionDelay: i * (800 / servicos.length)
							}}
						>
							<div className="mt-2" >
								<CardAcertado servico={servico} farmacia />
							</div>
						</Zoom>

					})
					: <div>
						<Alert>Nenhum serviço para acertar</Alert>
					</div>}
			</div >}
	</>
}