import { useContext, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useNavigate } from "react-router-dom"
import MensagemApp from "../../servicos/config/MensagemApp"
import ProfissionalAPI from "../../servicos/ProfissionalAPI"
import { ContextoGeral } from "../../contextos/ContextoGeral"

export default function AlertDialog({ options, open, setOpen, onClose }) {
  const navegar = useNavigate()

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => {
    if (options.opcao != "B") setOpen(false)

    if (onClose) onClose()
  }

  const handleVoltar = () => {
    setOpen(false)
    navegar("/app/home")
  }

  return <div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="titulo"
      aria-describedby="corpo"
    >
      <DialogTitle id="titulo">
        {options?.titulo}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="corpo">
          {options?.mensagem}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {options?.opcao == "B"
          ? <Button onClick={handleVoltar}>Voltar</Button>
          : <Button onClick={handleClose}>Fechar</Button>
        }
      </DialogActions>
    </Dialog>
  </div>
}

export function FakeGpsAlert({ open, setOpen, onClose, opcao }) {
  const navegar = useNavigate()
  const { dados } = useContext(ContextoGeral)

  const version = window.AndroidInterface?.recoverVersion() > 100.80

  const handleClose = () => {
    if (opcao != "B") setOpen(false)
    if (onClose) onClose()
  }

  const handleVoltar = () => {
    if (opcao != "B") setOpen(false)
    navegar("/app/home")
  }

  useEffect(() => {
    if (version && open && opcao == "B")
      try {
        ProfissionalAPI.relatarAntifraude(dados, "LF")
        window.AndroidInterface?.setGPSFake()
      } catch {
        console.error(MensagemApp.funcIndisponivel)
      }

  }, [open])

  return <div>
    <Dialog
      open={version && open}
      onClose={handleClose}
      aria-labelledby="titulo"
      aria-describedby="corpo"
    >
      <DialogTitle id="titulo">
        {MensagemApp.tituloFakeGps}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="corpo">
          {opcao == "B" ? MensagemApp.msgFakeGPSBloqueio : MensagemApp.msgFakeGPSInf}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {opcao == "B"
          ? <Button onClick={handleVoltar}>Voltar</Button>
          : <Button onClick={handleClose}>Fechar</Button>
        }
      </DialogActions>
    </Dialog>
  </div>
}

export function BootClickAlert({ open, setOpen, onClose, opcao }) {
  const navegar = useNavigate()
  const { dados } = useContext(ContextoGeral)

  const version = window.AndroidInterface?.recoverVersion() > 100.80

  const handleClose = () => {
    if (opcao != "B") setOpen(false)
    if (onClose) onClose()
  }

  const handleVoltar = () => {
    if (opcao != "B") setOpen(false)
    navegar("/app/home")
  }

  useEffect(() => {
    if (open && version) ProfissionalAPI.relatarAntifraude(dados, "AC")
  }, [open])

  return <div>
    <Dialog
      open={version && open}
      onClose={handleClose}
      aria-labelledby="titulo"
      aria-describedby="corpo"
    >
      <DialogTitle id="titulo">
        {MensagemApp.tituloBootClick}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="corpo">
          {opcao == "B" ? MensagemApp.msgBootClickBloqueio : MensagemApp.msgBootClickInf}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {opcao == "B"
          ? <Button onClick={handleVoltar}>Voltar</Button>
          : <Button onClick={handleClose}>Fechar</Button>
        }
      </DialogActions>
    </Dialog>
  </div>
}