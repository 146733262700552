import API from "./API.jsx"
import mensagemApp from './config/MensagemApp.js'

class MensagemAPI {
    static async qtdMensagensNaoLida(dados) {
        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: '/qtdeMensagensNaoLidas',
            params: {
                idProf: dados.idProf
            },
            method: "GET",
            metodo: "GET",
            cabecalho: {
                token: dados.token
            }
        }).then(res => {
            return res.data
        }).catch(rej => {
            return { codigo: 400, mensagem: mensagemApp.msgErroApi };
        })
    }

    static async buscarMensagens(dados, params) {
        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: "/mensagensLidasNaoLidas",
            method: "GET",
            metodo: "GET",
            params: params,
            cabecalho: {
                token: dados.token
            }
        }).then(res => { return res.data }).catch(rej => { return rej })
    }

    static async enviar(dados, mensagem) {

        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: "/enviarMensagem",
            method: "POST",
            metodo: "POST",
            data: {
                idProf: dados.idProf,
                mensagem: mensagem
            },
            cabecalho: {
                token: dados.token
            }
        }).then(res => { return res.data }).catch(rej => { return rej })
    }
}


export default MensagemAPI