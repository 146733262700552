import { Card } from "@mui/material"
import { format, parse } from "date-fns"

export default function ListagemRelatorio({ acertos }) {

    let key = 0
    let teste = '-'
    const mostrarDescricao = acertos.dados.resumo.descricao != '' && acertos.dados.resumo.descricao != '-'

    console.log(acertos)

    return (
        <div className="mb-3 overflow-auto">
            <Card className="p-3">
                <h5>Resumo</h5>
                <div className="d-flex justify-content-between">
                    <div>Saldo Anterior:</div>
                    <div>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(acertos.dados.resumo.saldoAnterior)}
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>Saldo Atual:</div>
                    <div>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(acertos.dados.resumo.saldoAtual ?? acertos.dados.resumo.saldoRestante)}
                    </div>
                </div>
                {acertos.dados.resumo.servicos.valorTotal && <div className="d-flex justify-content-between">
                    <div>Valor Total Serviços:</div>
                    <div>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(acertos.dados.resumo.servicos.valorTotal)}
                    </div>
                </div>}
                <div className="d-flex justify-content-between">
                    <div>Quantidade de Serviços:</div>
                    <div>
                        <div>{acertos.dados.resumo.servicos.quantidade}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>{acertos.dados.resumo.pagamento.total.label}:</div>
                    <div>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(acertos.dados.resumo.pagamento.total.valor)}
                    </div>
                </div>
                {mostrarDescricao
                    ? <>
                        <hr />
                        {acertos.dados.resumo.descricao}
                    </>
                    : ''}
            </Card>

            {(acertos.dados.creditos.length > 0) ? (
                <Card className="mt-3 p-3 text-success border border-success">
                    <h5>Créditos</h5>
                    {
                        acertos.dados.creditos.map(credito => {
                            key++

                            return (
                                <Card
                                    key={`cred-${key}`}
                                    className="p-2 bg-success mt-2 text-light"
                                >
                                    {credito.descricao}
                                    <div>
                                        {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(credito.valor)}
                                    </div>
                                    <div>
                                        {format(parse(credito.dataVencimento, 'yyyy-MM-dd', new Date()), "dd/MM/yyyy")}
                                    </div>
                                </Card>
                            )
                        })
                    }
                </Card>
            ) : ('')}

            {(acertos.dados.debitos.length > 0) ? (
                <Card className="mt-3 p-3 text-danger border border-danger">
                    <h5>Débitos</h5>
                    {
                        acertos.dados.debitos.map(debito => {
                            key++

                            return (
                                <Card
                                    key={`deb-${key}`}
                                    className="p-2 bg-danger mt-2 text-light"
                                >
                                    {debito.descricao}
                                    <div>
                                        {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(debito.valor)}
                                    </div>
                                    <div>
                                        {format(parse(debito.dataVencimento, 'yyyy-MM-dd', new Date()), "dd/MM/yyyy")}
                                    </div>
                                </Card>
                            )
                        })
                    }
                </Card>
            ) : ('')}

            {(acertos.dados.ultimosServicos.length > 0) ? (
                <Card className="mt-3 p-3">
                    <h5>{acertos.dados?.configPagina?.labelUltimosServicos ?  acertos.dados.configPagina.labelUltimosServicos : 'Serviços dos últimos 40 dias :'}</h5>
                    {acertos.dados.ultimosServicos.map(servico => {
                        key++

                        return <Card
                            key={`serv-${key}`}
                            className="mb-3 p-3"
                        >
                            <div className="d-flex justify-content-between">
                                {servico.id != null && servico.id != '' &&
                                    <h5>#{servico.id}</h5>}
                                <span>
                                    {format(parse(servico.data, 'yyyy-MM-dd', new Date()), "dd/MM/yyyy")}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Pagamento</div>
                                <div>{servico.formaPagamento}</div>
                            </div>
                            {servico.valor && <div className="d-flex justify-content-between">
                                <div>Valor do serviço</div>
                                <div>
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(servico.valor)}
                                </div>
                            </div>}
                            {servico.valorProfissional.valor && <div className="d-flex justify-content-between">
                                <div>Valor de comissão</div>
                                <div>
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(servico.valorProfissional.valor)}
                                </div>
                            </div>}
                            {servico.valorProfissional.valorDevolver &&  <div className="d-flex justify-content-between">
                                <div>Valor a devolver</div>
                                <div>
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(servico.valorProfissional.valorDevolver)}
                                </div>
                            </div>}
                            {servico.novoSaldo &&  <div className="d-flex justify-content-between">
                                <div>Saldo</div>
                                <div>
                                    {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(servico.novoSaldo)}
                                </div>
                            </div>}
                        </Card>
                    })}
                </Card>
            ) : ('')}
        </div >
    )
}