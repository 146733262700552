import { useEffect, useState } from "react";
import APISemNode from "../../../../servicos/APISemNode";

export default function useSalvarSala(dados, location) {
    const [msg, setMsg] = useState("");
    const [idSala, setIdSala] = useState(null);

    useEffect(() => {

        // Verifica se há um ID de sala e atualiza o estado
        if (dados?.idSala && !location?.state?.idServico) {

            if (!idSala) {
            
                setIdSala(dados?.idSala);
            }
            return; // Sai do efeito se a sala já existir
        }

        // Se não há ID, segue com a criação da sala
        if (!dados || !dados.idProf || !dados.token || !dados.nome) {
            // console.error("Dados insuficientes para salvar a sala.");
            setMsg("Dados insuficientes para salvar a sala.");
            return;
        }

        let dominio = `https://${dados.dominio}`;
        let uri = "sem/v1/rotas.php/modulo-chat-profissional/inserirSala";
        if (dados.dominio === 'mototaxionline.srv.br') {
            uri = "sem/v1/rotas/modulo-chat-profissional/inserirSala";
        }

        const paraCentral = dados.paraQuem === 'central' ? 'S' : '';
        const paraCliente = dados.paraQuem === 'cliente' ? dados.idSolicitante : 0;

        const dadosSalvar = {
            codProf: dados?.idProf,
            codFuncionario: location?.state?.idFuncionario,
            nome: dados?.room,
            idChat_nomes: 1,
            iniciadoPor: dados?.idProf,
            tipoUsuario: "P",
            ...(paraCentral && { paraCentral }),
            ...(paraCliente && { paraCliente })
        };

        const salvarSala = async () => {
            try {
                const res = await APISemNode.requisicaoPOST(
                    dominio,
                    dadosSalvar,
                    uri,
                    dados?.token
                );

                if (res?.status == 200 && res?.dados) {
                    setIdSala(res.dados?.dados?.idSala || null);
                } else {
                    setMsg("Não foi possível salvar a sala. Por favor, tente novamente.");
                }
            } catch (error) {
                console.error("Erro ao salvar a sala:", error);
                setMsg("Houve um erro ao salvar a sala. Por favor, tente novamente mais tarde.");
            }
        };

        salvarSala();
    }, [dados]); // Adiciona 'executarHook' como dependência

    return { idSala, msg };
}
