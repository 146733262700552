import { Alert, Backdrop, Card, Zoom } from "@mui/material"
import { format, parse } from "date-fns"
import { useContext, useState } from "react"
import { Accordion, Col, Row } from "react-bootstrap"
import { Campo } from "../../../componentes/form/campo"
import { Spinner } from "../../../componentes/Spinner"
import { MdAssignment } from "react-icons/md"
import { motion } from "framer-motion"
import { configAnim } from "../../../servicos/config/configAnim"

import AppAPI from "../../../servicos/AppAPI"

import ListagemRelatorio from "./componentes/listagem"

import { ContextoGeral } from "../../../contextos/ContextoGeral"

export default function RelatorioAcertos({ config, handleBackdrop }) {
    const [listaAcertos, setListaAcertos] = useState('')
    const [tipoRelatorio, setTipoRelatorio] = useState('receber')
    const [intervalo, setIntervalo] = useState(false)
    const [intervaloReceber, setIntervaloReceber] = useState('AH')

    const [datas, setDatas] = useState({})
    const [datasR, setDatasR] = useState({})

    const { dados } = useContext(ContextoGeral)

    const manejarValorRelatorio = (event) => {
        setListaAcertos('')
    }

    const manejarTipoRelatorio = (event) => {
        setListaAcertos('')
        setTipoRelatorio(event.target.value)

        if (event.target.value == 'recebidos') {
            setIntervalo(false)
        }
    }

    const manejarIntervalo = (e) => {
        setListaAcertos('')
        setIntervalo(e.target.value)
        if (e.target.value != 'intervalo') {
            setDatas({})
        }
    }


    const manejarIntervaloReceber = (e) => {
        setIntervaloReceber(e.target.value)
        if (e.target.value != 'AH') {
            setDatasR({})
        }
    }

    const manejarData = (e) => {
        let json = { ...datas }

        if (e.target.id == "dataInicial") {
            json.dataInicial = e.target.value

        } else if (e.target.id == "dataFinal") {
            json.dataFinal = e.target.value
        }

        setDatas(json)
    }

    const manejarDataR = (e) => {
        let json = { ...datasR }

        console.log('manejarDataR')
        if (e.target.id == "dataInicialR") {
            json.dataInicial = e.target.value

        } else if (e.target.id == "dataFinalR") {
            json.dataFinal = e.target.value
        }

        setDatasR(json)
    }

    async function buscarAcertos() {
        setListaAcertos(
            <div className="mt-4">
                <Spinner />
            </div>
        )

        if (tipoRelatorio == 'recebidos') {
            let params = {}
            let valido = false

            if (intervalo == "intervalo") {
                params.periodo = "intervalo"

                if (datas.dataInicial == '' || datas.dataInicial == undefined || datas.dataFinal == '' || datas.dataFinal == undefined) {

                    setListaAcertos(
                        <Alert severity="warning">
                            Favor verifique a data inserida
                        </Alert>
                    )
                } else {
                    params = {
                        ...params,
                        inicio: datas.dataInicial,
                        fim: datas.dataFinal
                    }
                    valido = true
                }
            } else {
                params.periodo = "ultimo"
                valido = true
            }

            if (valido) {
                let acertos = await AppAPI.buscarRelatorioAcertoUltimos(dados, params)

                if (acertos.resultado) {
                    let retorno = ""

                    if (acertos.dados != "") {
                        let key = 0

                        retorno = (
                            <div className="mb-3">
                                {acertos.dados.relatorio.map(relatorio => {
                                    key++
                                    return (
                                        <Card
                                            key={`resumosUltimos-${key}`}
                                            className="p-3 mb-3"
                                            onClick={() => handleBackdrop(relatorio)}
                                        >
                                            <div className="d-flex justify-content-between align-items-center h4 mt-2 py-2">
                                                <div className="text-secondary">
                                                    {format(parse(relatorio.resumo.dataVencimento,
                                                        'yyyy-MM-dd', new Date()
                                                    ), "dd/MM/yyyy")}
                                                </div>
                                                <div>
                                                    {new Intl.NumberFormat('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    }).format(relatorio.resumo.debitoCredito)}
                                                </div>
                                                <div className="greyDisk">
                                                    <MdAssignment />
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })}
                            </div>
                        )

                    } else {
                        retorno = <Alert severity="warning" className="text-center">
                            {acertos.mensagem}
                        </Alert>
                    }
                    setListaAcertos(retorno)
                } else {
                    setListaAcertos(
                        <Alert severity="warning" className="text-center">
                            {acertos.mensagem}
                        </Alert>
                    )
                }
            }
        } else {
            let params = {}
            let valido = false

            if (intervaloReceber == "S") {
                params.periodo = "S"

                if (datasR.dataInicial == '' || datasR.dataInicial == undefined || datasR.dataFinal == '' || datasR.dataFinal == undefined) {

                    setListaAcertos(
                        <Alert severity="warning">
                            Favor verifique a data inserida
                        </Alert>
                    )
                } else {
                    params = {
                        ...params,
                        inicio: datasR.dataInicial,
                        fim: datasR.dataFinal
                    }
                    valido = true
                }
            } else {
                params.periodo = "AH"
                valido = true
            }

            if (valido) {
                let acertos = await AppAPI.buscarRelatorioAcerto(dados, params)

                if (acertos.resultado) {
                    setListaAcertos(<ListagemRelatorio acertos={acertos} />)

                } else {
                    setListaAcertos(
                        <Alert>
                            {acertos.mensagem}
                        </Alert>
                    )
                }
            }
        }
    }

    return (
        <>
            <motion.div
                initial={configAnim.initial}
                animate={configAnim.animate}
                exit={configAnim.exit}
                transition={configAnim.transition}
            >
                <div className="my-3">
                    <Campo
                        tipo="select"
                        label={config.filtros.acertos.tipoRelatorio.label}
                        options={config.filtros.acertos.tipoRelatorio.opcoes}
                        obrigatorio={config.filtros.acertos.tipoRelatorio.obrigatorio}
                        valor={tipoRelatorio}
                        manejarValor={manejarValorRelatorio}
                        onChange={manejarTipoRelatorio}
                    />
                    {(tipoRelatorio == 'recebidos') ? (
                        <>
                            <div className="mt-3">
                                <Campo
                                    tipo="select"
                                    id="ordenacaoAcertos"
                                    label={config.filtros.acertos.periodo.label}
                                    options={config.filtros.acertos.periodo.opcoes}
                                    obrigatorio={config.filtros.acertos.periodo.obrigatorio}
                                    onChange={manejarIntervalo}
                                    value={intervalo}
                                />
                            </div>
                            {intervalo == 'intervalo' ? (
                                <Zoom in={true}>
                                    <div>
                                        <p className="text-center mb-0">Selecione o intervalo das datas</p>
                                        <Row>
                                            <Col xs="6">
                                                <Campo
                                                    id="dataInicial"
                                                    label="Data Inicial"
                                                    tipo="date"
                                                    onChange={manejarData}
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <Campo
                                                    id="dataFinal"
                                                    label="Data Final"
                                                    tipo="date"
                                                    onChange={manejarData}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Zoom>
                            ) : ('')}
                        </>
                    ) : ('')}
                    {(tipoRelatorio == 'receber') ? (
                        <>
                            <div className="mt-3">
                                <Campo
                                    tipo="select"
                                    id="ordenacaoAcertos"
                                    label={'Período'}
                                    options={[{ label: 'Até Hoje (Últimos 40 dias)', value: 'AH', key: 1 }, { label: 'Selecionar período', value: 'S', key: 2 }]}
                                    obrigatorio={true}
                                    onChange={manejarIntervaloReceber}
                                    value={intervaloReceber}
                                />
                            </div>
                            {intervaloReceber == 'S' ? (
                                <Zoom in={true}>
                                    <div>
                                        <p className="text-center mb-0">Selecione o intervalo das datas</p>
                                        <Row>
                                            <Col xs="6">
                                                <Campo
                                                    id="dataInicialR"
                                                    label="Data Inicial"
                                                    tipo="date"
                                                    onChange={manejarDataR}
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <Campo
                                                    id="dataFinalR"
                                                    label="Data Final"
                                                    tipo="date"
                                                    onChange={manejarDataR}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Zoom>
                            ) : ('')}
                        </>
                    ) : ('')}
                    <Row className="g-0 mx-0 mt-3">
                        <button onClick={buscarAcertos}>Buscar</button>
                    </Row>
                </div>
                <div
                    className="py-3"
                >
                    {listaAcertos}
                </div>
            </motion.div>
        </>
    )
}