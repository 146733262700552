import { motion } from "framer-motion"
import React, { useContext, useEffect, useState } from "react"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { configAnim } from "../../../servicos/config/configAnim"

import { Alert, Backdrop, Card, CircularProgress, Divider } from "@mui/material"
import { MdArrowForwardIos, MdConstruction, MdDelete, MdOutlineFileDownload, MdPolicy, MdTextSnippet } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import packInfo from "../../../../package.json"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import ProfissionalAPI from "../../../servicos/ProfissionalAPI"

export default function Configuracoes() {
  const [menu, setMenu] = useState(false)
  const [loading, setLoading] = useState(true)
  const [urlTermoDeUso, setUrlTermoDeUso] = useState(null)
  const [feedback, setFeedback] = useState(null)
  const [linkTermos, setLinkTermos] = useState(null)
  const [warn, setWarn] = useState(false)

  const { dados } = useContext(ContextoGeral)
  const navegar = useNavigate()

  const handleVisualizarTermosDeUso = () => {
    try {
      if (!!window.AndroidInterface && urlTermoDeUso) {
        if (typeof window.AndroidInterface.redirectLink === "function") {
          window.AndroidInterface.redirectLink(urlTermoDeUso)
        }
      } else {
        setFeedback({
          type: "error",
          message: "Houve um erro ao tentar visualizar o seu aceite, por favor, tente novamente"
        })
      }
    } catch (error) {
      setFeedback({
        type: "error",
        message: "Houve um erro ao tentar visualizar o seu aceite, por favor, tente novamente"
      })
    }
  }

  const handleLimparCache = () => {
    setWarn(true)
    setTimeout(() => {
      try {
        if (window.AndroidInterface)
          window.AndroidInterface.showAndroidVersion("limpando cache...")
        window.AndroidInterface.clearCacheStorage()
      } catch {
        if (window.AndroidInterface)
          window.AndroidInterface.showAndroidVersion("Função para limpar cache indisponível nesta versão do aplicativo")
        else alert("Função para limpar cache indisponível nesta versão do aplicativo")
      }
    }, 4000)
  }

  useEffect(() => {
    const buscarTermoDeUso = async () => {
      if (dados) {
        const resposta = await ProfissionalAPI.buscarTemoDeUsoAceito(dados)
          .finally(() => setLoading(false))

        if (resposta.dados?.url_termo_uso) {
          setUrlTermoDeUso(resposta.dados?.url_termo_uso)
        }
      }
    }

    const buscarLinkTermoDeUso = async () => {
      if (dados) {
        const resposta = await ProfissionalAPI.buscarTermosUso(dados)
          .finally(() => setLoading(false))

        if (resposta.dados?.link) {
          setLinkTermos(resposta.dados.link)
        }
      }
    }

    if (dados) {
      Promise.all([buscarTermoDeUso(), buscarLinkTermoDeUso()])
        .finally(() => setLoading(false))
    }
  }, [dados])

  return (
    <motion.div
      className="telaAjustada pb-0"
      initial={configAnim.initial}
      animate={configAnim.animate}
      exit={configAnim.exit}
      transition={configAnim.transition}>
      <div>
        <TopoMenu voltar="/app/home" titulo="CONFIGURAÇÕES" setShow={setMenu} />
        {loading ? (
          <div className="d-flex justify-content-center mt-3">
            <CircularProgress size={24} />
          </div>
        ) : (
          <React.Fragment>
            <div
              className="mx-3 py-3 d-flex align-items-center"
              onClick={() => { navegar('/app/politicaPrivacidade') }}>
              <MdPolicy size={24} />
              <h5 className="fs-6 ms-3 mb-0">Política de privacidade</h5>
              <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
            </div>
            <Divider />
            {linkTermos && (
              <React.Fragment>
                <div
                  className="mx-3 py-3 d-flex align-items-center"
                  onClick={() => { navegar('/app/termosUso') }}>
                  <MdTextSnippet size={24} />
                  <h5 className="fs-6 ms-3 mb-0">Termos de uso</h5>
                  <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
                </div>
                <Divider />
              </React.Fragment>
            )}
            {urlTermoDeUso && (
              <React.Fragment>
                <div
                  className="mx-3 py-3 d-flex align-items-center"
                  onClick={handleVisualizarTermosDeUso}>
                  <MdOutlineFileDownload size={24} />
                  <h5 className="fs-6 ms-3 mb-0">
                    Visualizar meu aceite
                  </h5>
                  <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
                </div>
                <Divider />
              </React.Fragment>
            )}
            {window.AndroidInterface && window.AndroidInterface.recoverVersion() >= 101.02 && <>
              <div
                className="mx-3 py-3 d-flex align-items-center"
                onClick={handleLimparCache}>
                <MdDelete size={24} />
                <h5 className="fs-6 ms-3 mb-0">
                  Limpar Cache
                </h5>
                <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
              </div>
              <Divider />
            </>}
            {dados.dominio == "mexsolog.com.br" && <>
              <div
                className="mx-3 py-3 d-flex align-items-center"
                onClick={() => { navegar('/app/lgpd') }}>
                <MdTextSnippet size={24} />
                <h5 className="fs-6 ms-3 mb-0">LGPD</h5>
                <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
              </div>
              <Divider />
            </>}
            {window.location.hostname == "localhost" && <>
              <div
                className="mx-3 py-3 d-flex align-items-center"
                onClick={() => { navegar('/playground') }}>
                <MdConstruction size={24} />
                <h5 className="fs-6 ms-3 mb-0">Debug</h5>
                <MdArrowForwardIos size={14} className="ms-auto opacity-50" />
              </div>
            </>}

            {feedback && (
              <Alert className="m-3" severity={feedback.type}>
                {feedback.message}
              </Alert>
            )}
          </React.Fragment>
        )}
      </div>
      <div className='text-center mb-2 opacity-75 small'>
        <div>Versão {!!window.AndroidInterface && window.AndroidInterface.recoverVersion()} • {packInfo.version}</div>
      </div>
      {warn && <ModalCache warn={warn} />}
      <MenuLateral show={menu} setShow={setMenu} ativo="5" />
    </motion.div>
  )
}

function ModalCache({ warn }) {
  const [time, setTime] = useState(4)

  useEffect(() => {
    if (time > 0) setTimeout(() => setTime(prev => prev - 1), 1000)
  }, [time])

  useEffect(() => {
    if (warn) setTime(4)
  }, [])

  return <Backdrop in={warn} sx={{
    zIndex: 5000,
    backdropFilter: "blur(5px)"
  }}>
    <Card className="w-75 p-3">
      <h4>O cache está sendo removido!</h4>
      O aplicativo irá reiniciar em {time} segundos
    </Card>
  </Backdrop>
}