import styled from "styled-components"

const Div = styled.div`
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    top: ${props => (props.top ?? '-0.3rem')};
    right: ${props => (props.right ?? '-0.3rem')};
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 0.8rem;
    height: 1.2rem;
    width: 1.2rem;
    line-height: 1.6;
    background-color: ${props => props.color};
    transform: translate(0%, 0%);
`

export default function Badge(props) {
    return (
        <Div
            top={props.top}
            right={props.right}
            relative={props.relative ?? false}
            color={props.color ?? 'red'}
        >
            {props.valor != 0 ? props.valor : ''}
        </Div>
    )
}