import React from "react";
import styled from "styled-components";
import ReactInputMask from "react-input-mask";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { MdFileUpload } from "react-icons/md";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 3.5rem;
	border-radius: 0.5rem;
	${(props) => (props.outline ? "border: 2px solid var(--grey-500)" : "")}
`;

export const Label = styled.label`
	position: absolute;
	color: ${(props) =>
		props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--grey-500)")};
	font-weight: normal;
	font-size: 1rem;
	opacity: 0.75;
	order: 1;
	padding-left: 2px;
	pointer-events: none;
	text-shadow: none;
	transform-origin: left top;
	transition: 200ms ease all;
	transform: scale(1) translate(1.2rem, 1rem);
`;

export const Select = styled.select`
	box-sizing: border-box;
	border-radius: 0;
	display: flex;
	line-height: 2rem;
	text-shadow: none;
	border: 2px solid
		${(props) => (props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--grey-500)"))};
	border-radius: 8px;

	color: ${(props) =>
		props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--primaria)")};
	flex: 1 1 auto;
	order: 2;
	padding: 1rem;
	transition: 200ms ease all;
	background-color: ${(props) => (props.autorizado ? "var(--bs-gray-400)" : "transparent")};

	&:focus,
	&:focus + ${Label} {
		outline: 0;
		color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
		border-color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
	}

	&:not(:placeholder-shown) + ${Label} {
		opacity: 1;
		transform: translate(1.2rem, 0.1rem) scale(0.7);
	}
`;

const Date = styled(ReactInputMask)`
	font-size: 95%;
	line-height: 2rem;
	text-shadow: none;
	border: 2px solid
		${(props) => (props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--grey-500)"))};
	border-radius: 8px;
	height: 3.5rem;
	transition: 0.4s;
	background-color: ${(props) => (props.autorizado ? "var(--bs-gray-400)" : "transparent")};

	box-sizing: border-box;
	width: 100%;

	color: ${(props) =>
		props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--primaria)")};
	order: 2;
	padding: 0.8rem 0.6rem;
	transition: 200ms ease all;

	&:focus,
	&:focus + ${Label} {
		outline: 0;
		color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
		border-color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
	}

	&:not(:placeholder-shown) + ${Label} {
		opacity: 1;
		transform: translate(1rem, 0.1rem) scale(0.7);
	}
`;

const File = styled.button`
	box-sizing: border-box;
	border-radius: 0;
	display: flex;
	align-items: center;
	line-height: 2rem;
	text-shadow: none;
	font-size: 0.8rem;
	border: 2px solid
		${(props) =>
		props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--primaria-bright)")};
	border-radius: 8px;
	transition: 200ms ease all;
	background-color: ${(props) => (props.autorizado ? "var(--bs-gray-400)" : "var(--primaria-bright)")};

	color: ${(props) => (props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "white"))};
	flex: 1 1 auto;
	order: 2;
	padding: 0.6rem 1.2rem;
	padding-right: 0.2rem;

	&:focus,
	&:focus + ${Label} {
		outline: 0;
		color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
		border-color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria-bright)")};
	}

	&:not(:placeholder-shown) + ${Label} {
		opacity: 1;
		transform: translate(1.2rem, 0.1rem) scale(0.7);
	}
`;

const Input = styled(ReactInputMask)`
	box-sizing: border-box;
	border-radius: 0;
	display: flex;
	line-height: 2rem;
	text-shadow: none;
	border: 2px solid
		${(props) => (props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--grey-500)"))};
	border-radius: 8px;
	transition: 200ms ease all;
	background-color: ${(props) => (props.autorizado ? "var(--bs-gray-400)" : "transparent")};

	color: ${(props) =>
		props.autorizado ? "var(--bs-success)" : props.danger ? "var(--bs-danger)" : "var(--primaria)"};
	flex: 1 1 auto;
	order: 2;
	padding: 0.6rem 1.2rem;
	padding-right: 0.2rem;

	&:focus,
	&:focus + ${Label} {
		outline: 0;
		color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
		border-color: ${(props) => (props.autorizado ? "var(--bs-success)" : "var(--primaria)")};
	}

	&:not(:placeholder-shown) + ${Label} {
		opacity: 1;
		transform: translate(1.2rem, 0.1rem) scale(0.7);
	}

	&:read-only {
		background-color: #ccc;
		opacity: 0.5;
	}
`;

const LabelRadio = styled.label`
	margin-left: 5px;
	color: ${(props) =>
		props.autorizado ? "var(--bs-success)" : (props.danger ? "var(--bs-danger)" : "var(--primaria)")};
	transition: 200ms ease all;
`;

export function Campo(props) {
	let tipo = "";
	if (props.tipo === "texto") {
		tipo = "text";
	} else if (props.tipo === "arquivo") {
		tipo = "file";
	} else {
		tipo = props.tipo;
	}

	let extra = {};

	let campo = "";
	if (props.tipo === "select") {
		if (props.manejarValor) {
			extra.handleValue = props.manejarValor;
		}

		if (props.onChange) {
			extra.onChange = props.onChange;
		}

		campo = (
			<Container>
				<Select
					id={props.id}
					value={props?.valor ?? props?.value ?? ""}
					{...extra}
					autorizado={props.autorizado}
					danger={props.danger}
					disabled={props.disable}
				>
					<option key="default" value="" disabled></option>
					{props.options && Object.values(props.options).map((e, i) => {
						return <option key={e?.key ?? i} value={e?.value ?? ""}>
							{e.label ?? ""}
						</option>
					})}
				</Select>
				<Label htmlFor={props.id} danger={props.danger}
					autorizado={props.autorizado}
				>
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</Label>
			</Container >
		);
	} else if (props.tipo === "radio") {
		campo = (
			<div>
				<FormLabel htmlFor={props.id}>
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</FormLabel>
				<RadioGroup
					id={props.id}
					aria-labelledby={props.id}
					onChange={props.onChange}
					value={props?.value}
					name="radio-buttons-group"
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					{props.options.map((option, i) => {
						return (
							<FormControlLabel
								name={props.id}
								key={`chave-${i}`}
								label={<div style={{ fontSize: "0.9rem", marginLeft: "-0.25rem" }}>{option.label}</div>}
								value={option.value}
								control={<Radio />}
							/>
						);
					})}
				</RadioGroup>
			</div>
		);
	} else if (props.tipo === "arquivo") {
		campo = (
			<Container>
				<File
					id={props.id}
					value={props.value}
					type="button"
					autorizado={props.autorizado}
					danger={props.danger}
					onClick={props?.onClick ?? props?.onChange}
					disabled={props.disable}
					readOnly
				>
					<MdFileUpload size={28} />
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</File>
			</Container>
		);
	} else if (props.tipo === "date") {
		campo = (
			<Container>
				<Date
					id={props.id}
					type={props.tipo}
					className={props.className}
					placeholder={props?.placeholder ?? " "}
					onChange={props?.onChange}
					danger={props.danger}
					autorizado={props.autorizado}
				/>
				<Label danger={props.danger} autorizado={props.autorizado}>
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</Label>
			</Container>
		);
	} else if (props.tipo === "dateSelect") {

		campo = (
			<Container className={props.className}>
				<Input
					id={props.id}
					type={props.tipo}
					data-tipo={props.tipo}
					placeholder={props?.placeholder ?? " "}
					autoComplete={props.autoComplete ?? ""}
					mask="99/99/9999"
					onKeyUp={props?.keyUp}
					onChange={props?.onChange}
					value={props.value}
					danger={props.danger}
					disabled={props.disable}
					autorizado={props.autorizado}
				/>
				<Label danger={props.danger}
					autorizado={props.autorizado}
				>
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</Label>
			</Container>
		);
	} else if (props.tipo === "codigoEmail") {
		campo = (
			<Container>
				<Input id={props.id} type="text" mask={props.mask} danger={props.danger} autorizado={props.autorizado} />
			</Container>
		);
	} else {
		let readOnly = true;

		if (Object.keys(props).includes("readOnly")) {
			readOnly = props.readOnly;
		}

		campo = (
			<Container className={props.className}>
				<Input
					id={props.id}
					type={tipo}
					placeholder={props?.placeholder ?? " "}
					autoComplete={props.autoComplete ?? ""}
					mask={props?.mask ?? ""}
					onKeyUp={props?.keyUp}
					onChange={props?.onChange}
					value={props.value}
					disabled={props?.disable}
					danger={props.danger}
					pattern={props?.pattern}
					readOnly={!readOnly}
					autorizado={props.autorizado}
				/>
				<Label autorizado={props.autorizado}>
					{props.label}
					{props?.obrigatorio ? <span className="red">✶</span> : ""}
				</Label>
			</Container>
		);
	}

	return <>{campo}</>
}
