import { useContext, useEffect } from "react";
import ServicoAPI from "../../servicos/ServicoAPI";
import { ContextoGeral } from "../../contextos/ContextoGeral";
import { reduceImage } from "../../servicos/Uteis";
import { useParams } from "react-router-dom";
import AppAPI from "../../servicos/AppAPI";

export default function useGallery(onFinish, onError) {
	const { dados } = useContext(ContextoGeral)
	const { id } = useParams()

	function callError(msg) {
		if (window.AndroidInterface)
			window.AndroidInterface.showAndroidVersion(msg)
		onError(msg)
	}

	useEffect(() => {
		window.setImage = async img => {
			AppAPI.inserirLog(dados, {
				msg: "Android enviou imagem com sucesso! Começou a aguardar carregamento da imagem base64 na webview.",
				menu: "Análise Protocolo",
				idProf: dados.idProf,
				idServico: id,
				texto: img ?? null
			})

			if (img) {
				const imageElement = new Image()
				imageElement.onload = async () => {
					AppAPI.inserirLog(dados, {
						msg: "Imagem base64 carregada, agora será processada.",
						menu: "Análise Protocolo",
						idProf: dados.idProf,
						idServico: id,
						texto: img
					})

					const base = await reduceImage(imageElement)
					if (base) {
						const resp = await ServicoAPI.salvarImagemCDN(dados, base)

						AppAPI.inserirLog(dados, {
							msg: "Mensagem da API recebida",
							menu: "Análise Protocolo",
							idProf: dados.idProf,
							idServico: id,
							texto: JSON.stringify(resp)
						})

						if (resp.resultado)
							onFinish(resp.dados)
						else if (resp.mensagem)
							onError(resp.mensagem)
						else
							onError("Não foi possível se conectar ao CDN")
					} else callError("Imagem não foi reduzida com sucesso")
				}
				imageElement.onerror = async () => {
					AppAPI.inserirLog(dados, {
						msg: "Falha ao carregar imagem na Webview!",
						menu: "Análise Protocolo",
						idProf: dados.idProf,
						idServico: id,
						texto: JSON.stringify(e)
					})
				}
				imageElement.src = img
			} else callError("Falha ao recuperar imagem do sistema")
		}

		window.cancelProtocol = () => callError("Operação cancelada pelo usuário")

		try {
			window.AndroidInterface.openGallery()
		} catch (err) {
			callError(err)
		}
	}, [])
}