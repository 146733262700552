import { MdFilterAlt } from "react-icons/md"
import styled from "styled-components"
import Badge from "./Badge"

const Div = styled.div`
    position: relative;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 2.5;
`

const Icone = styled.div`
    /* position: absolute; */
    top: 0;
    left: -0.5rem;
`

export default function Filtro({ valor, children, fun }) {
    return (
        <Div onClick={fun}>
            <Icone>
                <MdFilterAlt size={25} />
            </Icone>
                {children}
                <Badge valor={valor} top="0" relative />
        </Div>
    )
}