import { useContext, useEffect, useState } from "react"
import { Alert, Avatar, Backdrop, Button, Card, CircularProgress, IconButton } from "@mui/material"
import { motion } from "framer-motion"
import { MdAirlineSeatLegroomExtra, MdEdit } from "react-icons/md"

import { ContextoGeral } from "../../../contextos/ContextoGeral"

import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { configAnim } from "../../../servicos/config/configAnim"
import { Campo } from "../../../componentes/form/campo"
import { Spinner } from "../../../componentes/Spinner"
import ProfissionalAPI from "../../../servicos/ProfissionalAPI"
import AppAPI from "../../../servicos/AppAPI"
import CheckPermission from "../../../servicos/config/CheckPermission.js"
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import Refresher from "../../../componentes/uteis/Refresher"
import { resizePic } from "../../../servicos/Uteis"
import { CameraAlt, Close, Collections } from "@mui/icons-material"
import useCamera from "../../../hooks/general/useCamera.jsx"
import { useTheme } from "styled-components"
import useGallery from "../../../hooks/general/useGallery.jsx"

export default function MeusDados() {
	const [carregando, setCarregando] = useState(true)
	const [enviando, setEnviando] = useState(false)

	const [nome, setNome] = useState('')
	const [email, setEmail] = useState('')
	const [telefone, setTelefone] = useState('')
	const [celular, setCelular] = useState('')

	const [ver, setVer] = useState('')
	const [openCamera, setOpenCamera] = useState('')

	const [erroApi, setErroApi] = useState(false)
	const [valido, setValido] = useState(false)
	const [fotoAlt, setFotoAlt] = useState(false)
	const [carregandoFoto, setCarregandoFoto] = useState(false)

	const [configPagina, setConfigPagina] = useState({})

	const [resposta, setResposta] = useState('')

	const { dados, setDados } = useContext(ContextoGeral)

	const handleNome = event => {
		setNome(event.target.value)
	}
	const handleEmail = event => {
		setEmail(event.target.value)
	}
	const handleTelefone = event => {
		setTelefone(event.target.value.replace(/\D/g, ''))
	}
	const handleCelular = event => {
		setCelular(event.target.value.replace(/\D/g, ''))
	}

	async function buscarCampos() {
		let retorno = await AppAPI.buscarCamposDadosProf(dados)

		setErroApi(false)
		setValido(false)

		if (retorno?.resultado) {
			let jsonConfig = {}

			jsonConfig.nome = {}
			jsonConfig.nome.label = retorno.dados.campos.nome.label
			jsonConfig.nome.alterar = retorno.dados.campos.nome.alterar
			if (retorno.dados.campos.nome.obrigatorio) {
				jsonConfig.nome.obrigatorio = true
			}
			setNome(retorno.dados.campos.nome.valorEnviado)

			jsonConfig.email = {}
			jsonConfig.email.label = retorno.dados.campos.email.label
			jsonConfig.email.alterar = retorno.dados.campos.email.alterar
			if (retorno.dados.campos.email.obrigatorio) {
				jsonConfig.email.obrigatorio = true
			}
			setEmail(retorno.dados.campos.email.valorEnviado)

			jsonConfig.telefone = {}
			jsonConfig.telefone.label = retorno.dados.campos.telefone.label
			jsonConfig.telefone.alterar = retorno.dados.campos.telefone.alterar
			if (retorno.dados.campos.telefone.obrigatorio) {
				jsonConfig.telefone.obrigatorio = true
			}
			setTelefone(retorno.dados.campos.telefone.valorEnviado)

			jsonConfig.celular = {}
			jsonConfig.celular.label = retorno.dados.campos.celular.label
			jsonConfig.celular.alterar = retorno.dados.campos.celular.alterar
			if (retorno.dados.campos.celular.obrigatorio) {
				jsonConfig.celular.obrigatorio = true
			}
			setCelular(retorno.dados.campos.celular.valorEnviado)


			jsonConfig.foto = {}
			jsonConfig.foto.label = retorno.dados.campos.foto.label
			jsonConfig.foto.alterar = retorno.dados.campos.foto.alterar
			if (retorno.dados.campos.foto.obrigatorio) {
				jsonConfig.foto.obrigatorio = true
			}

			setConfigPagina(jsonConfig)

		} else {
			setErroApi(true)
			setValido(true)
		}
		setCarregando(false)
	}

	async function atualizarTokenApp(link) {
		const json = { ...dados, foto: link }
		setDados(json)
		let retornoTokenJWT = await AppAPI.gerarTokenJWT(json)

		if (retornoTokenJWT?.token !== "") {
			try {
				if (!!window.AndroidInterface)
					window.AndroidInterface.saveToken(retornoTokenJWT.token)
			} catch {
				console.log("não foi possível salvar o token")
			}
			setCarregandoFoto(false)
		}
	}

	const trocarAvatar = async (e) => {
		let v = 0 // VERSÃO ANDROID
		setCarregandoFoto(true)
		setFotoAlt(true)

		try {
			if (!!window.AndroidInterface) v = window.AndroidInterface.recoverVersion()
			else if (window.location.hostname === "localhost") v = 100.56
			else throw Error("Não foi possível acessar a versão do android")

			if (v >= 100.56) {
				const file = e.target.files[0]
				if (file) {
					const FR = new FileReader()

					if (file.name.match(/\.(jpg|jpeg|png)$/i)) {
						const image = await resizePic(file)

						// ALTERANDO FOTO NA API
						FR.onloadend = async () => {
							let retorno = await ProfissionalAPI.alterarFotoOld(dados, FR.result)

							if (retorno.resultado) {
								window.AndroidInterface.showAndroidVersion(retorno.mensagem)
								atualizarTokenApp(retorno.dados.link)
							}
						}
						FR.readAsDataURL(image)
					} else throw Error("Favor selecione uma imagem válida")
				} else throw Error("Nenhuma imagem selecionada")
			} else {
				if (CheckPermission.checkCamera()) {
					let editraFoto = configPagina.foto.alterar

					if (editraFoto) {
						setCarregandoFoto(true)

						// FUNÇÃO ANTIGA PARA ALTERAR FOTOS
						window.finalizouEnvio = function (res) {
							setCarregandoFoto(false)

							let retorno = JSON.parse(res)

							if (retorno.resultado) {
								window.AndroidInterface.showAndroidVersion(retorno.mensagem)
								atualizarTokenApp(retorno.dados.link)
							} else {
								window.AndroidInterface.showAndroidVersion(retorno.mensagem)
							}
						}

						if (!!window.AndroidInterface) {
							window.AndroidInterface.ativaCamera('F', parseInt(dados.idProf), 0, 0, 'FP', 'foto', 'alterarFoto', dados.token)
						}
					}
				} else if (!!window.AndroidInterface)
					window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera)
			}
		} catch (err) {
			try {
				window.AndroidInterface.showPermissaoCamera(err)
			} catch (e) {
				alert(err)
			}
		}
	}

	async function enviar() {
		setEnviando(true)
		setResposta(<div className="my-3">
			<Spinner />
		</div>)

		if (nome != '') {
			if (email != '') {
				let retorno = await ProfissionalAPI.alterarDados(dados, nome, email, celular, telefone)

				if (retorno.resultado) {
					let jsonDados = {
						...dados,
						nome: nome,
						email: email,
						celular: celular,
						telefone: telefone
					}

					setDados(jsonDados)
					setResposta(
						<Alert severity="success" className="text-center mt-3">
							Sucesso!
						</Alert>
					)
				} else {

					if (retorno?.mensagem) {
						setResposta(
							<Alert severity="error" className="text-center mt-3">
								{retorno.mensagem}
							</Alert>
						)
					} else {
						setResposta(
							<Alert severity="error" className="text-center mt-3">
								{MensagemApp.msgErroApi}
							</Alert>
						)
					}
				}
			} else {
				setResposta(
					<Alert severity="error" className="text-center mt-3">
						Você deve informar seu E-mail!
					</Alert>
				)
			}
		} else {
			setResposta(
				<Alert severity="error" className="text-center mt-3">
					Você deve informar seu nome!
				</Alert>
			)
		}
		setEnviando(false)
	}

	useEffect(() => {
		if (window.AndroidInterface)
			setVer(AndroidInterface?.recoverVersion())
	}, [])

	useEffect(() => {
		if (carregando) {
			buscarCampos()
		}
	}, [carregando])

	return <>
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			{erroApi ? (
				<>
					<TopoMenu voltar="/app/perfil" titulo="MEUS DADOS" />
					<div className="overflow-auto">
						<div className="d-flex justify-content-center mt-3 position-relative">
							{valido ? (
								<Refresher
									top="-1rem"
									setCarregando={setCarregando}
								/>
							) : ('')}
							<Alert severity="error" className="text-center mt-4">
								{MensagemApp.msgErroApi}
							</Alert>
						</div>
					</div>
				</>
			) :
				carregando
					? <div className="vh-100">
						<Spinner />
					</div>
					: <>
						<TopoMenu voltar="/app/perfil" titulo="MEUS DADOS" />
						<div className="overflow-auto mx-2">
							<div className="d-flex justify-content-center mt-3 position-relative">
								{carregandoFoto
									? <div style={{
										position: "absolute",
										transform: 'translate(0, -50%)',
										top: '50%',
									}}>
										<Spinner />
									</div>
									: configPagina.foto.alterar && <MdEdit
										size={50}
										style={{
											position: "absolute",
											transform: 'translate(0, -50%)',
											top: '50%',
											pointerEvents: 'none',
											zIndex: 50
										}}
									/>}
								<Avatar
									src={dados.foto}
									sx={{ width: 128, height: 128, opacity: 0.7, backgroundColor: '#33333377' }}
								/>
								{configPagina.foto.alterar
									? ver >= 102.26
										? <div style={{
											position: "absolute",
											height: "128px",
											borderRadius: "50%",
											width: "128px",
										}} onClick={() => {
											setOpenCamera(true)
										}} />
										: <input
											type="file"
											onChange={trocarAvatar}
											style={{
												position: "absolute",
												height: "100%",
												width: "128px",
												opacity: 0
											}}
										/>
									: <></>}
							</div>

							{configPagina?.nome && <div className="mt-3">
								<Campo
									label={configPagina.nome.label}
									onChange={handleNome}
									value={nome}
									obrigatorio={true}
									readOnly={configPagina.nome.alterar}
								/>
							</div>}

							{configPagina?.email && <div className="mt-3">
								<Campo
									label={configPagina.email.label}
									onChange={handleEmail}
									value={email}
									obrigatorio={true}
									readOnly={configPagina.email.alterar}
								/>
							</div>}

							{configPagina?.telefone && <div className="mt-3">
								<Campo
									label={configPagina.telefone.label}
									mask="(99) 9999-9999"
									onChange={handleTelefone}
									value={telefone}
									obrigatorio={true}
									readOnly={configPagina.telefone.alterar}
								/>
							</div>}

							{configPagina?.celular && <div className="mt-3">
								<Campo
									label={configPagina.celular.label}
									mask="(99) 99999-9999"
									onChange={handleCelular}
									value={celular}
									obrigatorio={true}
									readOnly={configPagina.celular.alterar}
								/>
							</div>}

							{resposta}
							<div className="mt-3">
								<button className="mb-3 w-100" onClick={enviar}>
									{enviando
										? <div className="my-2">
											<Spinner />
										</div>
										: 'Salvar'}
								</button>
							</div>
						</div>
					</>}
		</motion.div>
		<ModalFotoPerfil open={openCamera} close={() => {
			setOpenCamera(false)
		}} atualizarTokenApp={atualizarTokenApp} />
	</>
}

function ModalFotoPerfil({ open, close, atualizarTokenApp }) {
	const { dados, setDados } = useContext(ContextoGeral)

	const [camera, setCamera] = useState(false)
	const [gallery, setGallery] = useState(false)
	useEffect(() => {
	}, [])

	return <Backdrop in={open} sx={{
		zIndex: 5000,
		backdropFilter: "blur(5px)"
	}}>
		<Card className="w-75 p-3 position-relative">
			<IconButton disabled={camera || gallery} className="position-absolute end-0 top-0" onClick={close}>
				<Close />
			</IconButton>
			<div className="lh-1" style={{
				fontWeight: 800,
				width: "90%"
			}}>Qual forma deseja buscar sua imagem de perfil?</div>
			<div className="d-flex gap-3 mt-3">
				<Button onClick={() => {
					setCamera(true)
				}}><CameraAlt />Câmera</Button>
				<Button onClick={() => {
					setGallery(true)
				}}><Collections />Galeria</Button>
			</div>
			{camera && <Camera
				onFinish={async URL => {
					setGallery(false)
					ProfissionalAPI.alterarFoto(dados, URL).then(res => {
						if (res.resultado) {
							atualizarTokenApp(res.aux.link)
						} else {
							alert(res.mensagem)
						}
						setCamera(false)
						close()
					})
				}}
				onError={() => setCamera(false)}
			/>}
			{gallery && <Gallery
				onFinish={async URL => {
					setCamera(false)
					ProfissionalAPI.alterarFoto(dados, URL).then(res => {
						if (res.resultado) {
							atualizarTokenApp(res.aux.link)
						} else {
							alert(res.mensagem)
						}
						setGallery(false)
						close()
					})
				}}
				onError={() => setGallery(false)}
			/>}
		</Card>
	</Backdrop>
}

function Camera({ onFinish, onError }) {
	useCamera(onFinish, onError)
	return <div className="text-center">
		<CircularProgress />
	</div>
}

function Gallery({ onFinish, onError }) {
	useGallery(onFinish, onError)
	return <div className="text-center">
		<CircularProgress />
	</div>
}