import React from "react"
import pic1 from "./../../../assets/LGPD/0001.jpg"
import pic2 from "./../../../assets/LGPD/0002.jpg"
import pic3 from "./../../../assets/LGPD/0003.jpg"
import pic4 from "./../../../assets/LGPD/0004.jpg"
import pic5 from "./../../../assets/LGPD/0005.jpg"
import pic6 from "./../../../assets/LGPD/0006.jpg"
import pic7 from "./../../../assets/LGPD/0007.jpg"
import pic8 from "./../../../assets/LGPD/0008.jpg"
import pic9 from "./../../../assets/LGPD/0009.jpg"
import pic10 from "./../../../assets/LGPD/0010.jpg"
import pic11 from "./../../../assets/LGPD/0011.jpg"
import pic12 from "./../../../assets/LGPD/0012.jpg"
import pic13 from "./../../../assets/LGPD/0013.jpg"
import TopoMenu from "../../../componentes/gerais/TopoMenu"

export default function LGPD() {
	let objStyle = {
		width: "100%"
	}
	return <div>
		<TopoMenu voltar="/app/configuracoes" titulo="LGPD" />
		<div>
			<img
				style={objStyle}
				src={pic1}
			/>
			<img
				style={objStyle}
				src={pic2}
			/>
			<img
				style={objStyle}
				src={pic3}
			/>
			<img
				style={objStyle}
				src={pic4}
			/>
			<img
				style={objStyle}
				src={pic5}
			/>
			<img
				style={objStyle}
				src={pic6}
			/>
			<img
				style={objStyle}
				src={pic7}
			/>
			<img
				style={objStyle}
				src={pic8}
			/>
			<img
				style={objStyle}
				src={pic9}
			/>
			<img
				style={objStyle}
				src={pic10}
			/>
			<img
				style={objStyle}
				src={pic11}
			/>
			<img
				style={objStyle}
				src={pic12}
			/>
			<img
				style={objStyle}
				src={pic13}
			/>
		</div>
	</div>
}