import { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import { ContextoGeral } from '../../contextos/ContextoGeral'

const Div = styled.div`
    background-color: #ffffff;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    color: var(--primaria);
`

const Img = styled.img`
    width: 8rem;
`

export function Logotipo({ aux }) {
    const navegar = useNavigate()

    const { dados } = useContext(ContextoGeral)

    return <Div>
        {!!dados.config.logo &&
            <Img src={dados.config.logo} onClick={() => {
                if (window.location.pathname == "/app/home" && aux)
                    aux()
                else
                    navegar("/app/home")
            }} alt="logotipo" />}
    </Div>
}