import { useContext, useEffect, useState, useRef } from "react"
import APISemNode from "./../../../../servicos/APISemNode"
import { ContextoGeral } from "../../../../contextos/ContextoGeral"
import TopoMenu from "../../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../../componentes/gerais/Menu"
import { Campo } from "../../../../componentes/form/campo"
import { Alert, Checkbox, Collapse, MenuItem } from "@mui/material"
import { configAnim } from "../../../../servicos/config/configAnim"
import { motion } from "framer-motion"
import { Spinner } from "../../../../componentes/Spinner"
import { Link, useNavigate, useLocation } from "react-router-dom"

export default function Abbiamo() {
	const [menu, setMenu] = useState(false)
	const [clientes, setClientes] = useState([])
	const [cliente, setCliente] = useState("")
	const [carregando, setCarregando] = useState(true)

	const { dados } = useContext(ContextoGeral)

	const location = useLocation();

	useEffect(() => {
		if (carregando)
			APISemNode.requisicaoGET(`https://${dados.dominio}`, `idProf=${dados.idProf}&token=${dados.token}`, "md/painelLumen/lumen/abbiamo/abbiamoPuxarEnderecos", dados.token).then(res => {

				setClientes(res)

			}).catch(rej => {
				setValidado(true);
			}).finally(() => {
				setCarregando(false)
			})
	}, [carregando])


	useEffect(() => {
		if (location && location.state) {
			const temp = location.state

			if (temp.qClientes > 1 && temp.enderecos.dados.enderecos.data.length > 1)
				if (temp && temp.idCliente)
					setCliente(temp.idCliente)
		}
	}, [])

	useEffect(() => {
		if (clientes?.dados?.length === 1) setCliente(clientes?.dados?.[0]?.value);

	}, [clientes]);

	return <motion.div
		style={{ height: "100vh" }}
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
		layout
	>
		<TopoMenu voltar="/app/puxarServico" titulo="Endereços para entrega" setShow={setMenu} />
		<MenuLateral show={menu} setShow={setMenu} />
		{carregando
			? <div className="w-100 h-100 mt-3 align-items-center justify-content-center">
				<Spinner />
			</div>
			: <div className="mx-3 mt-4">
				{clientes.dados?.length > 0
					? clientes.dados?.length > 1
						? <>
							<Campo
								tipo="select"
								label='Selecione um cliente'
								options={clientes.dados}
								obrigatorio
								valor={cliente}
								onChange={e => setCliente(e.target.value)} className="mt-4 text-center"
							/>
						</>
						: clientes.dados.map((cliente, i) => {
							return <h4>
								{cliente.label}
							</h4>
						})
					: <Alert severity="error" className="mx-3 mt-4 text-center">
						{clientes.mensagem
							? clientes.mensagem
							: 'Não foi encontrado clinetes no momento.'}
					</Alert>}
				{cliente != "" && <DetalhesCliente qClientes={clientes?.dados?.length} idCliente={cliente} location={location ? location : []} />}

				<p className="border rounded p-2 mt-3">
					Nesta página, é possível visualizar os endereços integrados com Abbiamo. Ao selecionar os endereços desejados, você terá a opção de criar uma rota personalizada e salvar um serviço para execução.
				</p>
			</div>}
	</motion.div>
}

function DetalhesCliente({ qClientes, idCliente, location }) {
	const { dados } = useContext(ContextoGeral)

	const [enderecos, setEnderecos] = useState([])
	const [loading, setLoading] = useState(true)
	const [validado, setValidado] = useState(true)
	const [selecionados, setSelecionados] = useState([])
	const [mostrarBotaoOrdenar, setMostrarBotaoOrdenar] = useState(false)
	const [loadingOrdenar, setLoadingOrdenar] = useState(false)

	const [init, setInit] = useState(false)

	const navegar = useNavigate()

	function navegacao(url, enderecos, ponto1, idCliente) {
		navegar(url, {
			state: {
				"idCliente": idCliente,
				"qClientes": qClientes,
				"ponto1": ponto1,
				"enderecos": enderecos
			}
		})
	}

	async function buscar() {
		await APISemNode.requisicaoGET(`https://${dados.dominio}`, `idCliente=${idCliente}&dominio=${dados.dominio}`, "md/mdLumen/lumen/abbiamo/listarEntregas", "").then(res => {

			setValidado(res.sucesso ?? false)
			if (res.sucesso) {
				setEnderecos(res);

				if (res?.dados?.enderecos?.data?.length > 1) {
					setMostrarBotaoOrdenar(true)
				} else {
					if (res?.dados?.enderecos?.data?.length > 0)
						navegacao('/app/abbiamo/enderecosOrdenados', res, res.dados.coletaP1, idCliente)
				}
			} else {
				setEnderecos([]);
			}

		}).catch(rej => {
			setValidado(true);
			setEnderecos([]);
		})

		// setTimeout(() => 
		setLoading(false)
		//  5000)
	}

	async function criarRota() {
		setLoadingOrdenar(true)

		const listaEnderecos = enderecos.dados.enderecos.data.filter(item => {
			if (selecionados.filter(selecionado => selecionado == item.id).length > 0)
				return item
		})

		const ponto1 = enderecos.dados.coletaP1

		const obj = {
			lista: [ponto1, ...listaEnderecos],
			token: dados.token
		}

		await APISemNode.requisicaoPOST(`https://${dados.dominio}`, obj, "md/painelLumen/lumen/abbiamo/ordenarEnderecos", "").then(res => {
			if (res.mensagemServico == 'Já está ordenado pela menor distância ou não foi possível ordenar.') {

				const objLista = {
					dados: {
						enderecos: {
							data: listaEnderecos
						}
					}
				};
				navegacao('/app/abbiamo/enderecosOrdenados', objLista, ponto1, idCliente)
			}

			setValidado(res.sucesso ?? false)
			if (res.sucesso) {

				navegacao('/app/abbiamo/enderecosOrdenados', res, enderecos.dados.coletaP1, idCliente)
			}

		}).catch(rej => {
			setValidado(true);
		})
		setLoadingOrdenar(false)
	}

	useEffect(() => {
		if (location && location.state) {

			const temp = location.state

			if (temp && temp.enderecos) {
				const idEndSelecionados = temp.enderecos.dados.enderecos.data.map((endereco) => {
					return endereco.id
				})
				if (idEndSelecionados.length > 1)
					setSelecionados(idEndSelecionados);
			}
		}
	}, [])

	useEffect(() => {
		if (idCliente) buscar()
	}, [idCliente])

	useEffect(() => {

		if (selecionados && selecionados?.length && enderecos && enderecos?.dados?.enderecos?.data.length && !init) {
			let temp = [];

			temp = enderecos.dados.enderecos.data.map(endereco => {
				if (selecionados.includes(endereco.id)) {

					return { ...endereco, checked: true }
				} else {

					return endereco
				}
			})

			setEnderecos(prev => ({
				...prev, dados: {
					...prev.dados,
					enderecos: {
						...prev.dados.enderecos,
						data: temp
					}
				}
			}))

			setInit(true)
		}
	}, [enderecos])

	useEffect(() => {
		if (loading) buscar()
	}, [loading])

	const valLista = enderecos
		&& enderecos.dados
		&& enderecos.dados.enderecos
		&& enderecos.dados.enderecos.data
		&& enderecos.dados.enderecos.data.length > 0

	return <div className="h-100">
		{loading
			? <div className="mt-4 text-center">
				<Spinner />
			</div>
			: validado
				? valLista
					? <div style={{ maxHeight: "72vh", overflowY: "auto" }}>
						{enderecos.dados.enderecos.data.map((endereco, i) => {
							return <ItemLista key={enderecos?.dados?.enderecos?.data?.id} endereco={endereco} objSelecionados={{ selecionados, setSelecionados }} />
						})}
					</div>
					: <Alert severity="error" className="mx-3 mt-4 text-center">
						Não possui endereços ainda.
					</Alert>

				: <Alert severity="error" className="mx-3 mt-4 text-center">
					Não há endereços no momento.
				</Alert>
		}

		{mostrarBotaoOrdenar && selecionados && selecionados?.length > 0
			? selecionados && selecionados?.length > 1
				? <>
					<button
						className="btnFullW my-1"
						onClick={criarRota}
					>
						Roteirizar
					</button>
				</>
				:
				<>
					<button
						className="btnFullW my-1"
						onClick={criarRota}
					>
						Proximo
					</button>
				</>
			: ''
		}

		{
			loadingOrdenar && <div className="w-100 h-100 my-3 align-items-center justify-content-center">
				<Spinner />
			</div>
		}
	</div >
}

function ItemLista({ endereco, objSelecionados }) {
	const { selecionados, setSelecionados } = objSelecionados

	function onChange(e) {
		if (selecionados.includes(endereco.id))
			setSelecionados(prev => prev.filter(id => id !== endereco.id))
		else
			setSelecionados(prev => [...prev, endereco.id])
	}

	return <MenuItem className="p-1 m-0 w-100">
		<label htmlFor={endereco.id} className="text-truncate small" >
			<Checkbox
				id={String(endereco.id)}
				checked={selecionados.includes(endereco.id)}
				onChange={onChange}
			/>
			{endereco.rua}, {endereco.numero}, {endereco.bairro}, {endereco.cidade}, {endereco.estado}
		</label>
	</MenuItem>
}