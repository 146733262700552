import { useContext, useEffect, useState } from "react"
import ReactDOMServer from 'react-dom/server'

import { EmojiPeople } from "@mui/icons-material"
import { divIcon } from "leaflet"

import ReactLeafletDriftMarker from "react-leaflet-drift-marker"
import styled from "styled-components"
import { Popup } from "react-leaflet"
import { ContextoGeral } from "../contextos/ContextoGeral"
import ProfissionalAPI from "../servicos/ProfissionalAPI"

const IconPersonStyle = styled(EmojiPeople)`
   transform: translate(-40%, -100%) scale(2);
   stroke: black;
   stroke-width: 1px;
   fill: var(--primaria-brighter) !important;
`

export const iconPerson = divIcon({
	html: ReactDOMServer.renderToString(<IconPersonStyle />),
	className: "iconesMapa marker-cluster-custom",
})

export default function MapaProfMarker(props) {
	const animDuration = 200

	const [show, setShow] = useState(props.inicial.lenth > 0)
	const [coordenadas, setCoordenadas] = useState(props.inicial)

	const { dados } = useContext(ContextoGeral)
	async function changeLocation(init = false) {
		if (window.AndroidInterface) {
			if (typeof window.AndroidInterface.getLocation === 'function') {
				let localizacao = await JSON.parse(window.AndroidInterface.getLocation())

				if (localizacao.latitude != 0 && localizacao.longitude != 0) {
					setShow(true)
					setCoordenadas([localizacao.latitude, localizacao.longitude])

					// ENVIAR LOCALIZAÇÂO PARA RASTREAMENTO
					if (init) ProfissionalAPI.enviarLocalizacao(dados, {
						idProf: dados.idProf,
						la: localizacao.latitude,
						lo: localizacao.longitude
					})
				}
			}
		}
	}

	useEffect(() => {
		if (dados.statusOnline) changeLocation(true)
	}, [props.inicial, dados])

	useEffect(() => {
		if (dados.statusOnline)
			setTimeout(async () => {
				changeLocation()
			}, 5000)
		else setShow(false)
	}, [coordenadas, dados])

	useEffect(() => {
		if (!dados.statusOnline) setShow(dados.statusOnline)
	}, [dados.statusOnline])

	return <>
		{show && <ReactLeafletDriftMarker
			position={coordenadas}
			icon={iconPerson}
			duration={animDuration}
		>
			<Popup>
				Você está aqui!
			</Popup>
		</ReactLeafletDriftMarker>}
	</>
}