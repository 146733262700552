export default class CheckPermission {
    static checkCamera() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkCamera()
        return true
    }

    static checkLocationEnabled() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkLocationEnabled()
        return true
    }

    static checkBackgroundLocationAvailable() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkBackgroundLocationAvailable()
        return true
    }

    static checkLocationAvailable() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkLocationAvailable()
        return true
    }

    static checkStorage() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkStorage()
        return true
    }

    static checkNotificationAvailable() {
        if (window.AndroidInterface)
            return window.AndroidInterface.checkNotificationAvailable()
        return true
    }

    static checkBatteryOptimization() {
        if (!!window.AndroidInterface)
            return window.AndroidInterface.checkBatteryOptimization()
        return true
    }
}