import API from "./API.jsx"


export default class AppAPI {
	static async buscarConfig(dominio) {
		return await API.post("/appProfissional/acessar", {
			dominio: dominio,
			rota: "/config",
			method: "GET",
			metodo: "GET"
		}).then(res => res.data).catch(rej => rej)
	}

	static async validarTokenApp(dominio, token) {
		console.log("token: ", window.AndroidInterface.recoverIdFirebase())
		return await API.post("/appProfissional/acessar", {
			dominio: dominio,
			rota: "/validarToken",
			method: "POST",
			metodo: "POST",
			data: {
				token: token,
				idFirebase: window.AndroidInterface
					? window.AndroidInterface.recoverIdFirebase()
					: 'jgqijegqg',
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async configApp(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/configApp",
			method: "GET",
			metodo: "GET",
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async configApk(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/configApk",
			method: "GET",
			metodo: "GET",
			cabecalho: {
				"token": 'uisahdi135241sa5d64a'
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async decodificarTokenJWT(tokenJWT) {
		return await API.post("/decodificarTokenJWT", {
			data: {
				token: tokenJWT
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async gerarTokenJWT(dados) {
		return await API({
			url: "/gerarTokenJWT",
			method: "POST",
			metodo: "POST",
			data: dados
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarNovaIndicacao(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/telaIndicacao",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarConfigRelatorio(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/camposRelatorio",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarCamposDadosProf(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/camposDadosProf",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarRelatorioCotacoes(dados, periodo) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/relatorioCotacoes",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				periodo: periodo
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarRelatorioConcluidos(dados, params, page) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/relatorioServicosConcluidos",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				pagina: page,
				...params
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarRelatorioAcerto(dados, params) {
		console.log(' --buscarRelatorioAcerto-- ')
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/relatorioAcerto",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				...params
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarRelatorioAcertoUltimos(dados, params) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/relatorioUltimosAcertos",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				...params
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarServicosExecucao(dados, local) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/enderecosProximos",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				...local
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async buscarConfigCadastro(dados) {
		return API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/configCadastro",
			method: "GET",
			metodo: "GET",
			cabecalho: {
				token: dados.token
			}
		}).then(res => { return res.data }).catch(rej => { return rej })
	}

	static async inserirLog(dados, corpo) {
		if ((dados.dominio == "mototaxionline.srv.br" && (dados.idProf == 231 || dados.idProf == 10)) || (dados.dominio == "transadventoexpress.com.br" && dados.idProf == 7))
			return API.post("/appProfissional/acessar", {
				dominio: dados.dominio,
				rota: "/log",
				method: "POST",
				metodo: "POST",
				cabecalho: {
					token: dados.token
				},
				data: corpo
			}).then(res => { return res.data }).catch(rej => { return rej })
	}
}
