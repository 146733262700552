import styled from "styled-components"

export const Btn = styled.button`
    width: 50vw;
    pointer-events: none;
    background-color: ${props => props.cor};
    font-size: 1.1rem;
    line-height: 1.1;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 1.2rem;
    color: #fff;

    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: 0.4s;
    opacity: 1;
    /* border: 0.25rem solid var(--primaria-pura); */

    &:disabled {
        background-color: #000;
        opacity: 0.2;
        pointer-events: none;
    }
`

export function BtnConectar(props) {
    return (
        <Btn cor={props.cor} disabled={props.disabled}>
            {props.texto}
        </Btn>
    )
}