import { Avatar, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DimencaoIconesDias from "./dimencaoIconesDias";
import './style.css';

export default function CardsHorarios({ horario, cores, origemHorario }) {
    
    if (horario?.podeCadastrarSeg || horario?.podeCadastrarTer || horario?.podeCadastrarQua || horario?.podeCadastrarQui || horario?.podeCadastrarSex || horario?.podeCadastrarSab || horario?.podeCadastrarDom) {


        const [avatarWidth, avatarHeight] = DimencaoIconesDias();

        const navegar = useNavigate()

        function navegacao(url, horarioId, cores, profissionalDiasInscrito) {
            navegar(url, {
                state: {
                    "horarioid": horarioId,
                    cores,
                    origem: origemHorario,
                    profissionalDiasInscrito: profissionalDiasInscrito
                }
            })
        }

        function detalhes(horarioId, cores, profissionalDiasInscrito) {
            navegacao('/app/horariosDisponiveis/detalhes', horarioId, cores, profissionalDiasInscrito)
        }
console.log(horario?.seg + '  ' + horario?.podeCadastrarSeg);
        let corSeg = horario?.seg && horario?.seg === "S" && horario?.podeCadastrarSeg
            ? horario?.diasInscritos?.seg === "S" ? "green" : "white"
            : "red"
        let corTer = horario?.ter && horario?.ter === "S" && horario?.podeCadastrarTer
            ? horario?.diasInscritos?.ter === "S" ? "green" : "white"
            : "red"
        let corQua = horario?.quart && horario?.quart === "S" && horario?.podeCadastrarQua
            ? horario?.diasInscritos?.qua === "S" ? "green" : "white"
            : "red"
        let corqui = horario?.quint && horario?.quint === "S" && horario?.podeCadastrarQui
            ? horario?.diasInscritos?.qui === "S" ? "green" : "white"
            : "red"
        let corSex = horario?.sex && horario?.sex === "S" && horario?.podeCadastrarSex
            ? horario?.diasInscritos?.sex === "S" ? "green" : "white"
            : "red"
        let corSab = horario?.sab && horario?.sab === "S" && horario?.podeCadastrarSab
            ? horario?.diasInscritos?.sab === "S" ? "green" : "white"
            : "red"
        let corDom = horario?.dom && horario?.dom === "S" && horario?.podeCadastrarDom
            ? horario?.diasInscritos?.dom === "S" ? "green" : "white"
            : "red"

        let labelSeg = corSeg === "white" ? "black" : "white"
        let labelTer = corTer === "white" ? "black" : "white"
        let labelQua = corQua === "white" ? "black" : "white"
        let labelQui = corqui === "white" ? "black" : "white"
        let labelSex = corSex === "white" ? "black" : "white"
        let labelSab = corSab === "white" ? "black" : "white"
        let labelDom = corDom === "white" ? "black" : "white"

        return <div
            className="my-4 p-2"
            style={{ border: "2px solid gray", borderRadius: "5px" }}
            onClick={e => detalhes(horario.id, cores, horario?.diasInscritos)}
        >
            <div className="row">
                <div className="col-8">
                    <span>
                        {horario?.nome}
                    </span>
                </div>

                {origemHorario === "HD"
                    ? <div className="col-4">
                        <span className="inscrevase">
                            inscreva-se
                        </span>
                    </div>
                    : <></>}
            </div>

            <Stack direction={"row"} spacing={1.9} marginTop={2}>
                <Avatar sx={{ bgcolor: corSeg, color: labelSeg, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Seg</Avatar>
                <Avatar sx={{ bgcolor: corTer, color: labelTer, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Ter</Avatar>
                <Avatar sx={{ bgcolor: corQua, color: labelQua, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qua</Avatar>
                <Avatar sx={{ bgcolor: corqui, color: labelQui, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qui</Avatar>
                <Avatar sx={{ bgcolor: corSex, color: labelSex, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sex</Avatar>
                <Avatar sx={{ bgcolor: corSab, color: labelSab, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sab</Avatar>
                <Avatar sx={{ bgcolor: corDom, color: labelDom, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Dom</Avatar>
            </Stack>
            <Stack direction={"row"} spacing={2} marginTop={1}>

                {horario?.segHi && horario.segHf && horario?.seg === "S" && horario?.podeCadastrarSeg
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.segHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.segHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.segHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.segHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.segHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.segHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.segHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.segHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.terHi && horario?.terHf && horario?.ter === "S" && horario?.podeCadastrarTer
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>

                        {
                            new Date("1970-01-01T" + horario.terHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.terHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.terHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.terHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.terHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.terHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.terHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.terHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.quarHi && horario?.quarHf && horario?.quart === "S" && horario?.podeCadastrarQua
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.quarHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.quarHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.quarHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.quarHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.quarHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.quarHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.quarHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.quarHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.quintHi && horario?.quintHf && horario?.quint === "S" && horario?.podeCadastrarQui
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.quintHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.quintHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.quintHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.quintHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.quintHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.quintHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.quintHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.quintHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.sexHi && horario?.sexHf && horario?.sex === "S" && horario?.podeCadastrarSex
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.sexHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.sexHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.sexHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.sexHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.sexHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.sexHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.sexHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.sexHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.sabHi && horario?.sabHf && horario?.sab === "S" && horario?.podeCadastrarSab
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.sabHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.sabHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.sabHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.sabHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.sabHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.sabHf + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.sabHf + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.sabHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }

                {horario?.domHi && horario?.domHf && horario?.dom === "S" && horario?.podeCadastrarDom
                    ? <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}>
                        {
                            new Date("1970-01-01T" + horario.domHi + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.domHi + "Z").getUTCMinutes() < 10
                                ? '0' + new Date("1970-01-01T" + horario.domHi + "Z").getUTCMinutes()
                                : new Date("1970-01-01T" + horario.domHi + "Z").getUTCMinutes()
                        }
                        <span className="quebrarLinha">-</span>
                        {
                            new Date("1970-01-01T" + horario.domHf + "Z").getUTCHours()
                        }
                        :
                        {
                            new Date("1970-01-01T" + horario.domHf + "Z").getUTCMinutes() < 10
                            ? '0' + new Date("1970-01-01T" + horario.domHf + "Z").getUTCMinutes()
                            : new Date("1970-01-01T" + horario.domHf + "Z").getUTCMinutes()
                        }
                    </span>
                    : <span style={{ fontWeight: "bold", color: "black", fontSize: "9.9px", width: "22%", textAlign: "center" }}></span>
                }
            </Stack>
        </div>
    }
}