import axios from "axios"

export let baseURL = "https://appprofnodeprod.mototaxionline.com/"

switch (window.location.host) {
    case "localhost:3000":
        baseURL = "http://localhost:3001"
        break
    case "appprofhumb.mototaxionline.com":
    case "appprofteste.rj.r.appspot.com":
        baseURL = "https://node-dot-appprofteste.rj.r.appspot.com/"
        break
}

if (window.location.host.includes("192.168"))
    baseURL = `http://${window.location.host.split(":")[0]}:3001`

const API = axios.create({
    baseURL: baseURL,
    timeout: 30000
})

API.interceptors.response.use((res) => {
    if (res.data.aux != undefined) {
        if (["tokenInvalido", "usuarioInativo"].includes(res.data.aux)) {
            if (window.AndroidInterface) {
                const rotas = ["/validarToken", "/login", "/salvarImagemCDN"]

                if (!rotas.includes(JSON.parse(res.config.data).rota))
                    window.AndroidInterface.reloadPage()
            } else {
                window.location.href.replace('/')
            }
        }
    }
    return res
})

export default API