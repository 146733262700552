import { useEffect, useState } from "react";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import MenuLateral from "../../../componentes/gerais/Menu";
import { configAnim } from "../../../servicos/config/configAnim";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

import FiltroDiasSemana from "./filtroDiasSemana";

export default function horariosdisponiveis() {

    const [menu, setMenu] = useState(false)
    const { state } = useLocation();

    const [cores, setCores] = useState({
        Seg: state?.Seg ? state?.Seg : 'green',
        Ter: state?.Ter ? state?.Ter : 'green',
        Qua: state?.Qua ? state?.Qua : 'green',
        Qui: state?.Qui ? state?.Qui : 'green',
        Sex: state?.Sex ? state?.Sex : 'green',
        Sab: state?.Sab ? state?.Sab : 'green',
        Dom: state?.Dom ? state?.Dom : 'green'
    });

    const navegar = useNavigate()

    function navegacao(url, cores) {
        navegar(url, {
            state: {
                cores
            }
        })
    }


    function horariosInscritos(cores) {
        navegacao('/app/horariosDisponiveis/horariosInscritos', cores)
    }

    return <motion.div
        style={{ height: "100vh" }}
        initial={configAnim.initial}
        animate={configAnim.animate}
        exit={configAnim.exit}
        transition={configAnim.transition}
        layout
    >
        <TopoMenu voltar="/app/home" titulo="Horários disponíveis" setShow={setMenu}></TopoMenu>
        <MenuLateral show={menu} setShow={setMenu} />

        <div className="mx-3 mt-4">

            <button
                className="btnFullW"
                onClick={e => horariosInscritos(cores, setCores)}
            >
                Horários inscritos
            </button>
            <FiltroDiasSemana origemHorario={"HD"} cores={cores} setCores={setCores} />

        </div>
    </motion.div>
}