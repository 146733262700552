import { useState, useEffect } from 'react';

export default function DimencaoIconesDias() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [avatarDimensions, setAvatarDimensions] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let width = '10.5%';
        let height = '40px';

        if (windowWidth <= 375) {
            width = '10%';
            height = '33px';
        } else if (windowWidth > 320 && windowWidth < 370) {
            width = '9.8%';
            height = '33px';
        } else if (windowWidth <= 320) {
            width = '9.2%';
            height = '25px';
        }

        setAvatarDimensions([width, height]);
    }, [windowWidth]);

    return avatarDimensions;
}
