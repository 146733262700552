import { Backdrop, Button, CircularProgress, Collapse, IconButton } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { ContextoGeral } from '../../../contextos/ContextoGeral'
import { ChangeCircle, Close } from '@mui/icons-material'
import { reduceImage } from '../../../servicos/Uteis'
import axios from 'axios'
import { baseURL } from '../../../servicos/API'

export default function Protocolo({ open, openCamera, idEndereco, id, load, setProtocoloLoading }) {
	const [protocolo, setProtocolo] = useState("")
	const [loading, setLoading] = useState(false)
	const [mode, setMode] = useState(false)
	const [over, setOver] = useState(true)
	const [init, setInit] = useState(false)

	const { dados } = useContext(ContextoGeral)

	useEffect(() => {
		setInit(true)
		setTimeout(() => {
			setMode(true)
			setInit(false)
		}, 1000)

		setTimeout(() => setInit(true), 2000)
	}, [])

	useEffect(() => {
		if (open) {
			setOver(true)
		}
	}, [open])

	// ADICIONADO EFEITO PARA CORTAR TELA DE CARREGAMENTO PASSANDO DE 3 SEGUNDOS
	useEffect(() => {
		if (over) setTimeout(() => setOver(false), 3000)
	}, [over])

	const handleProtocolo = async () => {
		if (!loading) {
			setLoading(true)

			if (protocolo) {
				const image = new Image()
				image.src = protocolo

				const base = await reduceImage(image)

				if (base) {
					let retorno = await axios.post(baseURL + '/appProfissional/acessar', {
						dominio: dados.dominio ?? 'mototaxionline.srv.br',
						rota: '/salvarDadosFinalizar',
						metodo: "POST",
						cabecalho: {
							token: dados.token
						},
						data: {
							idEndereco: idEndereco,
							idProf: dados.idProf,
							idServico: id,
							identificador: "P",
							imagem: base,
							length: base.length
						}
					}, {
						timeout: 60000
					}).then(res => res.data).catch(rej => rej)

					if (retorno.resultado) {
						window.AndroidInterface.showAndroidVersion(retorno.mensagem)
						setProtocolo("")
						openCamera(false)
						load(true)
					} else {
						window.AndroidInterface.showAndroidVersion(retorno.mensagem ?? "Falha na foto, tente novamente")
						setProtocolo("")
					}
				}
			} else if (window.AndroidInterface) {
				window.AndroidInterface.showAndroidVersion("Falha na foto, tente novamente")
				setProtocolo("")
			}
			setLoading(false)
			setProtocoloLoading(false)
		}
	}

	return <>
		<Backdrop open={open && over} style={{
			height: "100%",
			background: "black",
			zIndex: 5000
		}}>
			<div className="text-white">
				Carregando...
			</div>

		</Backdrop>
		<Backdrop open={open} >
			<div className="bg-black h-100 w-100 d-flex flex-column justify-content-between">
				<div className="d-flex flex-column h-100 w-100 align-items-center position-relative">
					<Collapse in={!protocolo.length} className={"d-flex flex-column w-100 justify-content-center" + (!protocolo.length ? " h-100" : "")}>
						<div>
							{init && <Camera
								className="w-100 d-flex flex-column h-100 w-100 mt-5 align-items-center"
								onTakePhoto={(dataUri) => setProtocolo(dataUri)}
								onCameraStart={() => setTimeout(() => setOver(false), 500)}
								idealFacingMode={mode ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
								imageType={IMAGE_TYPES.JPG}
								onCameraError={e => { console.error("jiagjigajiF") }}
							/>}
						</div>
						<div className="d-flex justify-content-center text-white mt-2">
							<div role="button" onClick={() => {
								setOver(true)
								setTimeout(() => setMode(prev => !prev), 500)
							}}>
								<ChangeCircle sx={{ fontSize: "3rem" }} />
							</div>
						</div>
					</Collapse>
					<Collapse in={protocolo.length > 0} className={"d-flex flex-column w-100 justify-content-center" + (protocolo.length ? " h-100" : "")}>
						<div className="d-flex h-100 flex-column w-100 justify-content-center">
							<div>
								<img className='w-100' src={protocolo} />
								<h2 className='text-center text-white mt-3'>
									Confirma esta foto?
								</h2>
								<div className='d-flex gap-2 mx-3 F'>
									<Button variant='contained' disabled={loading} color="success" onClick={handleProtocolo} fullWidth>
										{loading
											? <CircularProgress size={22} />
											: "Sim"}
									</Button>
									<Button
										variant="contained"
										color="error"
										onClick={() => {
											if (!loading) setProtocolo("")
										}}
										disabled={loading}
										fullWidth
									>
										{loading
											? <CircularProgress size={22} />
											: "Não"}
									</Button>
								</div>
							</div>
						</div>
					</Collapse>
					<div style={{
						position: "absolute",
						top: "1rem",
						transform: "translate(50%)",
						right: "2.5rem"
					}}>
						<IconButton
							className='bg-white'
							sx={{
								padding: "0.7rem"
							}}
							onClick={() => {
								setProtocoloLoading(false)
								openCamera(false)
							}}
						>
							<Close />
						</IconButton>
					</div>
				</div>
			</div>
		</Backdrop>
	</>
}