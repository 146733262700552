import { Alert, Backdrop } from "@mui/material"
import { motion } from "framer-motion"
import { useState } from "react"
import { useRef } from "react"
import { useContext } from "react"
import { ButtonGroup, Col, InputGroup, Row } from "react-bootstrap"
import { MdArrowBack, MdClear, MdClose, MdSave, MdSend, MdUndo } from "react-icons/md"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SignatureCanvas from 'react-signature-canvas'
import { Campo } from "../../../componentes/form/campo"

import { BtnRedondo } from "../../../componentes/btnRedondo"

import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import ServicoAPI from "../../../servicos/ServicoAPI"
import { Spinner } from "../../../componentes/Spinner"
import { useEffect } from "react"
import { configAnim } from "../../../servicos/config/configAnim"
import { BlocoFlutuante } from "../../../componentes/uteis/blocoFlutuante"
import MensagemApp from "../../../servicos/config/MensagemApp.js"

export default function ServicoAssinar() {
    const navegar = useNavigate()

    const [BDAssinar, setBDAssinar] = useState(false)
    const [BDConfirmar, setBDConfirmar] = useState(false)

    const [imgSrc, setImgSrc] = useState('')
    const [nome, setNome] = useState('')
    const [doc, setDoc] = useState('')

    const [carregando, setCarregando] = useState(false)

    const [resposta, setResposta] = useState('')
    const [valido, setValido] = useState(null)

    const { id } = useParams()
    const { state } = useLocation()
    const { dados, setDados } = useContext(ContextoGeral)

    const canvas = useRef({})

    const handleBDConfirmar = () => { setBDConfirmar(false) }
    const handleBDAssinar = () => {
        if (!state?.nome && !state.documento) {
            navegar(`/app/servicos/${id}/finalizar`, { state: state })
        } else {
            setBDAssinar(false)
        }
    }

    const handleDoc = async (event) => {
        let value = '';

        if ((state?.documento?.tipo !== undefined)
            && state?.documento?.tipo !== 'RG') {

            value = event.target.value.replace(/\D/g, '')
        } else {
            value = event.target.value;
        }

        setDoc(value)
    }

    const handleNome = (event) => {
        setNome(event.target.value)
    }

    const avancar = async () => {
        setResposta(<div><Spinner /></div>)

        let msgResposta = ''
        if (state?.nome?.obrigatorio && nome.length == 0) {
            msgResposta = "Favor insira seu nome"

            setResposta(
                <Alert severity="error">
                    {msgResposta}
                </Alert>
            )
        } else if (state.documento?.obrigatorio && doc.length == 0) {
            msgResposta = "Favor insira seu documento"

            setResposta(
                <Alert severity="error">
                    {msgResposta}
                </Alert>
            )
        } else {
            if (state.documento.tipo == 'CPF') {
                if (doc.length == 11) {
                    let retorno = await ServicoAPI.validarCPF(dados, doc, state.documento.tipo)

                    if (retorno?.resultado) {

                        setResposta('')
                        setBDAssinar(true)
                    } else {
                        msgResposta = "CPF inválido, favor verifique e tente novamente"

                        setResposta(<Alert severity="error">
                            {msgResposta}
                        </Alert>)
                    }
                } else {
                    if (doc != '') {
                        msgResposta = "O número de caracteres digitado não confere"

                        setResposta(<Alert severity="error">
                            {msgResposta}
                        </Alert>)
                    } else {
                        setResposta('')
                        setBDAssinar(true)
                    }
                }
            } else {

                if (state.documento?.obrigatorio) {
                    if (doc.length > 6) {
                        setBDAssinar(true)
                        setResposta('')
                    } else {
                        setResposta('')
                        setResposta(<Alert severity="error">
                            Documento inválido, favor verifique e tente novamente
                        </Alert>)
                    }
                } else {
                    setBDAssinar(true)
                    setResposta('')
                }

            }
        }
    }

    const clear = () => canvas.current.clear()
    const salvar = async () => {
        setCarregando(false)
        if (canvas.current.isEmpty()) {
            if (!!window.AndroidInterface) {
                window.AndroidInterface.showAndroidVersion(MensagemApp.erroAssinatura)
            } else {
                alert(MensagemApp.erroAssinatura)
            }
        } else {
            setImgSrc(canvas.current.toDataURL())
            setBDConfirmar(true)
        }
    }

    const enviar = async () => {
        setCarregando(true)
        let image = canvas.current.toDataURL('base64string')

        let tipoDoc = 'RG'
        if (state?.documento?.tipo !== undefined) {
            tipoDoc = state.documento.tipo
        }

        let retorno = await ServicoAPI.enviarAssinatura(dados, id, state.idEndereco, nome, doc, tipoDoc, image)

        if (retorno?.status == 200) {

            if (retorno.data?.mensagem) {

                if (typeof window.AndroidInterface !== 'undefined') {
                    window.AndroidInterface.showAndroidVersion(retorno.data?.mensagem)
                } else {
                    alert(retorno.data?.mensagem)
                }

                if (retorno.data?.resultado) {
                    navegar(`/app/servicos/${id}/finalizar`, { state: state })
                } else {
                    setBDConfirmar(false)
                }

            } else {
                window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)
                setBDConfirmar(false)
            }

        } else {
            window.AndroidInterface.showAndroidVersion(MensagemApp.msgErroApi)
            setBDConfirmar(false)
        }
    }

    let nomeObrigatorio = state?.nome?.obrigatorio
    let docObrigatorio = state?.documento?.obrigatorio

    useState(() => {
        if (!state?.nome && !state?.documento) {
            setBDAssinar(true)
        }
    }, [])

    return (
        <>
            <motion.div
                style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: '1rem'
                }}
                initial={configAnim.initial}
                animate={configAnim.animate}
                exit={configAnim.exit}
                transition={configAnim.transition}
                layout
            >
                <TopoMenu voltar={`/app/servicos/${id}/finalizar`} state={state} titulo="ASSINATURA" />
                <div className="mx-3">
                    <h4 className="text-center">Digite os dados do recebedor</h4>
                    <div className="mt-3">
                        <Campo
                            label={state?.nome?.label ?? 'Nome'}
                            onChange={handleNome}
                            obrigatorio={state?.nome?.obrigatorio}
                            value={nome}
                            {...nomeObrigatorio}
                        />
                    </div>
                    <div className="mt-3">
                        <Campo
                            label={state?.documento?.label ?? 'RG'}
                            onChange={handleDoc}
                            obrigatorio={state?.documento?.obrigatorio ?? false}
                            value={doc}
                            tipo="number"
                            {...docObrigatorio}
                        />
                    </div>
                    <div className="mt-3">{resposta}</div>
                </div>

                <div className="mx-2">
                    <Row className="mx-2 mt-2">
                        <button onClick={avancar}>Avançar</button>
                    </Row>
                </div>
            </motion.div >

            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "#fff"
                }}
                open={BDAssinar}
            >
                <motion.div
                    style={{ height: '100%' }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="telaFill">
                        <SignatureCanvas
                            onBegin={() => {
                                document.activeElement.blur()
                            }}
                            canvasProps={{
                                // width: 100,
                                // height: 600,
                                className: 'canvasAssinatura'
                            }}
                            ref={canvas}
                        />
                        <div className="d-flex gap-2 mt-2">
                            <button
                                onClick={handleBDAssinar}
                                className="d-flex justify-content-center align-items-center gap-1 w-100"
                            >
                                <MdArrowBack size={20} />
                                Voltar
                            </button>
                            <button
                                onClick={clear}
                                className="d-flex justify-content-center align-items-center gap-1 w-100"
                            >
                                <MdClear size={20} />
                                Limpar
                            </button>
                        </div>
                        <Row className="mx-0 mt-2">
                            <button
                                onClick={salvar}
                                className="d-flex justify-content-center align-items-center gap-1"
                            >
                                <MdSave size={20} />
                                Salvar
                            </button>
                        </Row>
                    </div>
                </motion.div>
            </Backdrop>

            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                    backgroundColor: "#fff"
                }}
                open={BDConfirmar}
            >
                <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {carregando ? (
                        <Spinner />
                    ) : (
                        <div className="p-3">
                            <div className="text-center">
                                {nome}
                            </div>
                            <div className="text-center">
                                {doc}
                            </div>
                            <img
                                className="previewAssinatura"
                                src={imgSrc}
                                alt=""
                            />
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <BtnRedondo>
                                        <MdClose
                                            onClick={handleBDConfirmar}
                                        />
                                    </BtnRedondo>
                                </Col>
                                <Col className="d-flex justify-content-start">
                                    <BtnRedondo>
                                        <MdSend
                                            onClick={enviar}
                                        />
                                    </BtnRedondo>
                                </Col>
                            </Row>
                        </div>
                    )}
                </motion.div>
            </Backdrop>
        </>
    )
}