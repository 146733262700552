export function chamarTel(tel) {
	if (tel != '-' && tel.length > 0) {
		if (window.AndroidInterface) {
			if (typeof window.AndroidInterface.openPhone === "function") {
				window.AndroidInterface.redirectLink(`https://mappsistemas.com.br/ligar.php?tel=${tel}`)
			}
		}
	} else try {
		window.AndroidInterface.showAndroidVersion("telefone indisponível")
	} catch {
		alert("telefone indisponível")
	}
}

export function chamarWhats(tel) {
	if (tel != '-' && tel.length > 0) {
		if (window.AndroidInterface) {
			if (typeof window.AndroidInterface.openPhone === "function") {
				window.AndroidInterface.redirectLink(`https://wa.me/${tel}`)
			}
		}
	} else try {
		window.AndroidInterface.showAndroidVersion("telefone indisponível")
	} catch {
		alert("telefone indisponível")
	}
}
